import { createActions, handleActions } from "redux-actions";

const initialState = {
  marketing: {},
};

export const POST_MEMBER_ACCEPTED_MARKETING = "signup/member/POST_MEMBER_ACCEPTED_MARKETING";
export const CLEAR_MRK_PERSIST = "member/marketing/CLEAR_MRK_PERSIST";

const actions = createActions({
  [POST_MEMBER_ACCEPTED_MARKETING]: () => {},
  [CLEAR_MRK_PERSIST]: () => {},
});

const marketingReducer = handleActions({
  [POST_MEMBER_ACCEPTED_MARKETING]: (state, { payload }) => {
    state.marketing = {...payload};

    let newState = {...state};

    return newState;
  },

  [CLEAR_MRK_PERSIST]: () => {
    let newState = {
      marketing: {},
    };

    return newState;
  },
}, initialState);

export default marketingReducer;