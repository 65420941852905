import axios from "axios";
import { TEST_ADD } from "../port/Port";
import Swal from "sweetalert2";

export const API_UPLOAD_FILE_DOWNLOAD = "https://www.storrngo.kr:8443/fileDownload?";

export async function getNoticeListAPI() {

  const GET_NOTICE_LIST_URL = `${TEST_ADD}/noticeList?page_no=${0}`;
  let list = [];
  const now = new Date();

  await axios.get(GET_NOTICE_LIST_URL)
  .then(res => {
    if(1 === res.data.resultCode) list = res.data.result.filter(item => now <= new Date(item.date_ed) && "Y" === item.display_yn);
    else {
      // alert("공지사항 목록 조회 실패");
      Swal.fire({
        text: "공지사항 목록 조회 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
    }
  })
  .catch(err => console.error(err));

  // return list;
  return list.filter(item => "Y" === item.display_yn);
}

export async function getNoticeDetailInfoAPI(seq) {

  const GET_NOTICE_DETAIL_INFO_URL = `${TEST_ADD}/noticeInfo?seq=${seq}`;
  let obj = {};

  await axios.get(GET_NOTICE_DETAIL_INFO_URL)
  .then(res => {
    if(1 === res.data.resultCode) obj = res.data;
  })
  .catch(err => console.error(err));

  return obj;
}