import { createActions, handleActions } from "redux-actions";

const initialState = {
  serviceInfo: {},
};

export const POST_MEMBER_PURCHASED_SERVICE_INFO = "member/service/POST_MEMBER_PURCHASED_SERVICE_INFO";
export const CLEAR_SERVICE_PERSIST = "member/service/CLEAR_SERVICE_PERSIST";

const actions = createActions({
  [POST_MEMBER_PURCHASED_SERVICE_INFO]: () => {},
  [CLEAR_SERVICE_PERSIST]:() => {},
});

const serviceReducer = handleActions({
  [POST_MEMBER_PURCHASED_SERVICE_INFO]: (state, { payload }) => {
    state.serviceInfo = {...payload};

    let newState = {...state};

    return newState;
  },

  [CLEAR_SERVICE_PERSIST]: () => {
    let newState = {
      serviceInfo: {},
    };

    return newState;
  }
}, initialState);

export default serviceReducer;