function ServicePolicyContent1() {

  return (
    <>
      스토르앤고(STORR&Go) 이용약관<br />
      <br/>
      제1장 총칙<br/>
      <br />
      제1조 (목적)<br />
      본 약관은 스토리지엠 주식회사(이하 “회사”라 함)가 제공하는 “스토르앤고” 및 이에
      부수하는 제반 서비스의 이용과 관련하여 회사와 회원의 권리, 의무 및 책임사항, 기
      타 필요한 사항을 규정함을 목적으로 합니다.<br />
      <br />
      제2조(용어의 정의)<br />
      ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
      “회원”은 이 약관에 따라 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 자를 의미합니다.<br />
      “서비스”는 회사가 회원에게 제공하는 배송형 짐보관 서비스인 “스토르앤고(STORR&Go)”와 관련된 모바일 앱 가입 및 결제, 캐비닛 수거 및 배송, 캐비닛 보관 등과 관련한 서비스 일체를 의미합니다.<br />
      “모바일 앱”은 회사가 서비스를 제공하기 위하여 회원이 서비스 가입 및 서비스 결제를 할 수 있도록 운영하는 휴대폰용 소프트웨어입니다.<br />
      “캐비닛”은 회사가 회원의 물품을 안전하게 보관하기 위해서 제공하는 서비스 전용으로 제작된 가구입니다.<br />
      “수거 및 배송”은 회원의 신청 주소지와 보관센터 사이에서 회사가 캐비닛을 운송하는 과정입니다. 회원의 신청 주소지에서 보관센터로 운송하는 것을 “수거”, 보관센터에서 회원의 신청 주소지로 운송하는 것을 “배송”이라고 합니다.<br />
      “보관센터”는 회사가 서비스를 제공하기 위하여 회원의 물품이 포함된 캐비닛을 보관할 수 있는 공간을 의미합니다.<br />
      “물품”은 회원이 회사가 제공한 캐비닛 내에 보관하는 회원의 물품을 의미합니다.<br />
      ② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 상관례에 따릅니다.
      <br/><br/>
      제3조 (약관의 효력 및 변경)<br />
      ① 회사가 본 약관의 내용을 회사 홈페이지 또는 모바일 앱에 게재하거나 기타 방법(서면, 문자메시지, 전자메일 등)으로 이용자에게 통지하고, 그 내용에 동의한 이용자가 본 서비스에 가입하여 회원이 되었을 때 본 약관의 효력이 발생합니다.<br />
      ② 회사는 필요한 경우 관계 법령을 위반하지 않는 범위에서 본 약관을 변경할 수 있으며, 약관 변경 시에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방법으로 그 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 단, 회원의 권리 또는 의무에 관한 중요 규정 변경 시에는 적용일자 30일 전에 위 방법으로 공지하고 제22조 제1항의 방법으로 회원에게 통지합니다.<br />
      ③ 회사는 제2항에 따라 변경된 약관을 공지하거나 통지하면서, 회원에게 약관 변경
      적용 일까지 거부 의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한
      다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거
      부 의사를 표시하지 않았을 때 회원이 변경 약관에 동의한 것으로 간주합니다. 
      변경 약관에 동의하지 않는 회원에 대하여 서비스 이용이 불가할 수 있으며, 회
      원은 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.<br />
      <br />
      제4조 (이용계약의 체결 및 적용)<br />
      ① 본 서비스를 이용하고자 하는 자가 이 약관의 내용에 대하여 동의를 한 다음 서비스 이용 신청을 하고, 회사가 그 신청에 대해서 승낙함으로써 체결됩니다.<br />
      ② 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 거절할수 있습니다.<br />
      1. 실명이 아니거나 타인의 명의 또는 정보를 이용한 경우<br />
      2. 이용신청서 내용을 허위로 기재하거나 이용신청 요건을 충족하지 못한 경우<br />
      3. 관련 법령에서 금지하는 행위 또는 사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 서비스 이용을 신청하는 경우<br/>
      4. 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우<br/>
      5. 이용약관 위반으로 회원 자격을 박탈당하고 재가입하는 경우<br/>
      6. 미성년자 법정대리인의 동의 여부가 확인되지 않는 경우<br/>
      7. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우<br/>
      ③ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.<br/>
      <br />
      제5조 (약관 외 준칙)<br />
      ① 회사는 필요한 경우 특정 서비스에 관하여 적용될 개별약관을 정하여 제22조 제1
      항 또는 제2항에 따라 공지할 수 있으며, 개별약관에 대한 동의는 회원이 특정
      서비스를 최초로 이용할 경우 별도의 동의 절차를 거치게 됩니다. 이 경우 개별
      서비스에 대한 이용약관이 본 약관에 우선합니다.<br/>
      ② 본 약관 또는 개별약관에서 정하지 않은 사항과 이 약관의 해석에 관하여는 관계
      법령 또는 일반적인 상관례에 따릅니다.<br/>
      <br />
      제2장 개인정보 관리<br/>
      <br/>
      제6조 (개인정보의 보호) <br />
      회사는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 
      개인정보의 보호 및 사용에 대해서는 관계 법령 및 회사의 개인정보처리방침에 따릅
      니다. 다만, 회사가 직접 제공하는 서비스 이외의 링크된 서비스에 대해서는 회사의
      개인정보처리방침이 적용되지 않습니다.<br />
      <br />
      제3장 이용계약 당사자의 의무<br/>
      <br/>
      제7조 (회사의 의무)<br />
      ① 회사는 관련 법령, 이 약관에서 정하는 의무를 신의에 따라 성실하게 준수합니다.<br />
      ② 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설
      비에 장애가 생기거나 데이터 등이 멸실∙훼손된 때에는 천재지변, 비상사태, 현재
      의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없
      이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.<br/>
      <br />
      제8조 (회원의 의무)<br />
      ① 회원은 다음 각 호의 행위를 하여서는 안 됩니다.<br />
      1. 서비스의 신청 또는 변경 시 허위정보, 또는 타인의 정보를 사용하는 행위<br />
      2. 회사가 게시한 정보의 변경 또는 서비스와 운영 시스템에 장애를 주는 행위<br/>
      3. 회원 주소지의 출입 통로, 복도, 로비, 엘리베이터, 비상구, 비상계단, 도로, 
      방화벽 등의 공용공간에 회원에게 배정되어 이용중인 캐비닛을 적재 및 방
      치하는 행위<br/>
      4. 회원에게 배정되어 이용중인 캐비닛을 열흘 이상 반납을 지연하거나 수거
      신청을 지연하는 행위<br/>
      5. 캐비닛의 외부 및 내부를 훼손하거나 오염시키는 행위<br/>
      6. 캐비닛 내부에 금지된 물품을 보관하는 행위<br/>
      7. 캐비닛에 포함된 부속품을 무단으로 분리하거나 해체 또는 취득하는 행위<br/>
      8. 본인 아닌 제3자에게 서비스 이용권한을 부여하는 행위<br/>
      9. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
      10. 회사의 동의 없이 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 서비스를 이용하는 행위<br/>
      11. 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제∙유통∙조장하거나 상업
      적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를
      이용하는 행위<br/>
      12. 타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위<br/>
      13. 기타 불법적이거나 부당한 수단과 목적에 의한 행위<br/>
      ② 회원은 본 약관 및 회사가 서비스와 관련하여 고지하는 내용을 준수하여야 하며, 
      약관 및 고지 내용을 위반하거나 이행하지 아니하여 발생하는 모든 손실과 손해
      에 대하여 책임을 부담합니다.<br/>
      <br/>
      제4장 서비스 이용 및 제한<br/>
      <br />
      제9조 (서비스의 제공)<br />
      ① 회사는 제4조의 규정에 따라 이용계약이 완료된 회원에게 그 즉시 서비스를 이용
      할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의 필요에 따라 지정된 일자
      부터 서비스를 개시할 수 있습니다.<br />
      ② 회사는 회원에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를 포함하여
      기타 부가적인 서비스를 함께 제공할 수 있습니다.<br/>
      ③ 회사는 회원의 등급을 구분하고 서비스 이용요금, 이용횟수, 수거 및 배송횟수, 
      할인률, 쿠폰, 제공 서비스의 범위 등을 세분화하여 이용에 차등을 둘 수 있습니
      다.<br/>
      <br />
      제10조 (서비스의 이용)<br />
      ① 모바일 앱을 통한 서비스 제공은 회사의 업무상 또는 기술상 특별한 지장이 없는
      한 연중무휴 1일 24시간을 원칙으로 합니다. 단, 수거와 배송이 가능한 날짜는 운
      송 상황 또는 보관센터의 상황을 고려해 회사가 유동적으로 제안할 수 있습니다.<br />
      ② 제1항에도 불구하고 회사는 다음 각 호의 경우에는 서비스의 전부 또는 일부를
      일시 정지할 수 있습니다. 이 경우 회사는 사전에 그 정지의 사유와 기간을 제22
      조 제1항 또는 제2항의 방법으로 공지합니다. 다만, 사전에 공지할 수 없는 부득
      이한 사정이 있는 경우 사후에 공지할 수 있습니다.<br/>
      1. 시스템 정기점검, 서버의 증설 및 교체, 네트워크의 불안정 등의 시스템 운
      영상 필요한 경우<br/>
      2. 정전, 서비스 설비의 장애, 서비스 이용폭주, 기간통신사업자의 설비 보수 또는 점검 등으로 인하여 정상적인 서비스 제공이 불가능한 경우<br/>
      3. 수거 및 배송을 위한 운송 시스템의 오류, 차량의 고장, 운송인력의 부재 또
      는 사고 등으로 인하여 정상적인 서비스 제공이 불가능한 경우<br/>
      4. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 회사가 통제할 수
      없는 상황이 발생한 경우<br/>
      ③ 유료서비스의 경우에는 해당 서비스에 명시된 요금을 지급하여야 이용할 수 있습니다.<br/>
      <br />
      제11조 (서비스 금지 물품)<br />
      ① 회사는 다음 각 호의 경우에 물품의 보관을 거절할 수 있습니다.<br />
      1. 회원이 서비스 제공을 위해 회사가 요구한 사항을 기재하지 않은 경우<br/>
      2. 물품의 크기가 가로·세로·높이 세변의 합이 점당 200cm를 초과하거나, 최장
      변이 110cm를 초과하는 경우<br/>
      3. 물품의 무게가 점당 25kg을 초과하는 경우<br/>
      4. 물품의 가액이 점당 50만원을 초과하는 경우<br/>
      5. 귀금속, 귀중품, 보석, 글ㆍ그림, 골동품, 조각물 및 이와 비슷한 것<br/>
      6. 물품이 화약류, 인화물질, 핵연료물질, 방사성, 폭발성, 휴대용 배터리 그 밖
      의 유해한 특성이 있는 물건 등 위험한 물건인 경우<br/>
      7. 물품이 밀수품, 군수품, 부정임산물 등 관계기관으로부터 허가되지 않거나
      위법한 물건인 경우<br/>
      8. 물품이 현금, 카드, 어음, 수표, 유가증권, 인지, 우표 및 이와 비슷한 물건인
      경우<br/>
      9. 물품이 재생 불가능한 계약서, 원고, 서류, 설계서, 도안, 물건의 원본, 모형,
      증서, 장부, 금형(쇠틀), 목형(나무틀), 소프트웨어 및 이와 비슷한 물건 등인
      경우<br/>
      10. 물품이 누수, 악취 또는 심한 냄새가 나는 경우<br/>
      11. 물품이 살아 있는 동물, 동물사체 등인 경우<br/>
      12. 파열, 폭발, 발효, 자연발연, 자연발화할 가능성이 있는 물건인 경우물품이
      법령, 사회질서 기타 선량한 풍속에 반하는 경우<br/>
      13. 보관이 천재지변, 기타 불가항력적인 사유로 불가능한 경우<br/>
      ② 회원이 회사에게 고지하지 아니하고 제1항의 물품에 대한 서비스 이용을 하였음
      이 확인되는 경우, 회사는 즉시 이용계약을 해제 또는 해지할 수 있습니다.<br/>
      ③ 제1항에 따른 물품의 훼손, 멸실, 오염 등에 대하여는 회사는 책임을 지지 않으며,
      해당 물품으로 인하여 회사 또는 제3자에 대한 손해가 발생하는 경우 손해배상을
      청구할 수 있습니다.<br/>
      <br />
      제12조 (이용시 유의사항)<br />
      ① 회사는 캐비닛을 소유하며 회원에게 제공한 캐비닛은 회사의 고유자산입니다.<br />
      ② 회사는 서비스 제공을 위해 회원에게 캐비닛을 배정하고 이용계약 기간 동안 물
      품 보관 용도로 캐비닛을 이용할 수 있도록 제공합니다.<br />
      ③ 회사가 제공하는 서비스 요금은 월 단위로 적용되는 보관요금과 수거 및 배송에
      적용되는 운송요금으로 구분합니다.<br />
      ④ 회사는 월 보관요금 만으로 구성된 BASIC 요금제 (편도 운송요금 별도)와 월 보
      관요금 및 편도 운송요금이 결합된 패키지 상품을 판매할 수 있습니다. <br />
      ⑤ 회원은 최초 서비스 신청 시 모바일 앱에서 서비스 상품을 선택한 후, 월 1회 한
      도 내에서 서비스 상품 종류를 변경할 수 있습니다. <br />
      ⑥ 회원은 캐비닛 내에 냉장 또는 냉동 식품을 보관할 수 없습니다. <br />
      ⑦ 회원은 보관하는 물품의 중량, 성질 등에 맞는 적절한 포장을 해야 합니다.<br />
      ⑧ 의류, 이불, 전자 장비 등 습기에 예민한 물건은 반드시 제습제를 동봉하여 보관
      합니다.<br />
      ⑨ 의류, 이불류 등은 반드시 적절한 세탁 후 완전 건조하여 보관하고, 필요시 압축
      팩 등을 이용합니다.<br />
      ⑩ 물에 관련된 제품(정수기, 세탁기, 제습기 등)은 분리 및 완전 건조하여 보관합니
      다.<br />
      ⑪ 회원이 보관한 물품에 의해 캐비닛 내부 또는 외부가 오염된 경우 회원은 즉시오염 등을 제거하고 창고를 원상회복해야 하며 회원에 의해 캐비닛이 훼손, 오염
      또는 파손된 경우 이를 즉시 회사에 고지하고 발생한 손해 금액을 배상해야 합니
      다.<br />
      ⑫ 회사는 보관 물품에 대하여 회원에게 종류 및 상태를 물을 수 있으며, 정당한 사
      유 없이 답변을 거부할 경우 서비스 제한을 할 수 있습니다.<br />
      ⑬ 회사는 안전, 보건, 방역, 수사 및 기타 공공이익을 위해 필요하다고 인정하는 경
      우, 회원의 사전 동의 없이 캐비닛을 열어 보관중인 물품의 종류 및 상태를 확인
      할 수 있습니다. <br />
      ⑭ 회원은 배송된 캐비닛을 7일 이내에 수거 신청해야 하며, 배송된 날로부터 7일
      이상 경과한 경우 회사는 회원의 등록된 신용카드를 통해 매 7일마다 편도 수거
      요금에 해당하는 금액을 배송 지연 요금으로 청구 및 결제할 수 있습니다. <br />
      ⑮ 회원이 캐비닛을 배송 받은 후, 캐비닛의 수거 및 반납을 배송받은 날로부터 28
      일 이상 고의적으로 지연하는 경우 회사는 제9항의 배송 지연 요금을 회원에게
      청구하는 것과 별도로 경찰에 캐비닛의 도난 및 분실신고를 할 수 있습니다. <br />
      ⑯ 회원은 서비스 보관 요금 자동 결제를 위해 최소 1개 이상의 유효한 신용카드를
      모바일 앱에 등록하고 유지해야 하며, 회원 신용카드의 유효기간 만료 또는 결제
      한도 초과 등으로 인해 서비스 요금 결제가 불가한 상태가 7일 이상 경과한 경우
      회사는 제13조에서 정한 계약의 종료 절차를 진행할 수 있습니다. <br />
      ⑰ 회원의 본 조를 위반한 이용에 따른 손해가 발생한 경우 회사는 그 책임을 지지
      않습니다.<br />
      <br />
      제13조 (계약의 종료 및 장기 방치 물품)<br />
      ① 회사와 회원간 이용계약에서 정한 서비스 이용의 종기(이하 “이용계약 종료일”)
      이 경과하면 회원은 이용계약상 모든 권리를 상실합니다.<br />
      ② 회사와 사전 협의 없이 이용계약이 종료 후에도 캐비닛 내 보관물품을 회수하지
      않을 경우, 회사는 회원의 별도 동의 없이 회원이 사용중인 캐비닛과 보관된 물
      품을 압류할 수 있습니다. <br />
      ③ 회사와 사전 협의 없이 이용계약 종료일로부터 7일 이상 경과한 경우, 회사는 회
      원이 보관한 물품을 캐비닛에서 분리 및 수거하여 별도 보관할 수 있습니다.<br />
      ④ 회사는 회원이 이용계약 종료일로부터 7일 이상 경과한 경우, 그 기간 내에 보관
      물품을 찾아가지 아니하면 회사에서 임의처분 할 수 있음을 회원에게 통지합니다.<br />
      ⑤ 회원이 이용계약 종료일로부터 15일이 경과하기 전에 제6항에서 정한 금액을 지
      불하고 물품을 찾아가지 아니하는 경우, 회사는 해당 물품의 소유권 또는 임의
      처분 권리를 가지며 처분 수익금은 회사에 귀속됩니다.<br />
      ⑥ 회원은 이용계약 종료일 전에 캐비닛 최종 배송 신청을 완료해야 하며, 이용계약
      종료일이 지나면 최종 배송 신청을 할 수 없습니다. <br />
      ⑦ 회원은 이용계약 종료 시점에 배송된 캐비닛을 최종 수거 신청을 함으로써 반납
      해야 하며, 이용계약 종료일로부터 7일 이상 경과하기 전에 최종 수거 신청을 완
      료하지 않은 경우 이용계약은 자동으로 1개월 연장되고 모바일 앱에 등록된 신용
      카드로 서비스 이용요금이 결제됩니다. <br />
      ⑧ 회원이 이용계약 종료일 이후 보관 물품을 회수하고자 할 때에는 다음 각 호의
      순서로 진행됩니다.<br />
      1. 회사 고객센터 (전국대표번호 1670-4670)을 통해 물품의 회수 및 배송을 요청<br />
      2. 이용계약 종료일로부터 초과한 기간 동안의 월 보관요금과 가산이자 20%를
      적용한 금액(초과 사용요금)의 합계, 캐비닛 최종 배송 및 최종 수거를 위한
      운송요금을 지불<br />
      3. 최종 배송 날짜 및 최종 수거 날짜 확정<br />
      4. 확정된 최종 수거 날짜로부터 7일 이내에 캐비닛 최종 수거가 완료되지 않
      을 경우, 회사는 그 익일부터 다시 초과 사용요금이 부과하고 경찰에 캐비닛
      의 도난 및 분실을 신고함<br />
      ⑨ 계약만기일 이후 연장에 대한 보관 사용료를 지급하지 않을 경우 회사는 보관물
      품에 대해 유치권을 행사할 수 있습니다.<br />
      ⑩ 회사는 물품의 처분에 관련된 모든 비용을 이용자에게 청구할 수 있습니다.<br />
      <br />
      제14조 (계약 중도해지 및 자동결제 해지)<br />
      ① 회원은 이용기간 종료일 전에 회사와 회원 간의 이용계약을 해지할 수 있으며,
      이용계약 해지 절차는 다음 각 호의 순서로 진행됩니다.<br />
      1. 회사가 운영하는 모바일 앱을 통해 이용계약 해지를 요청<br />
      2. 모바일 앱을 통해 캐비닛 최종 배송 신청<br />
      3. 최종 배송된 캐비닛의 내부를 비우고 원상복구함<br />
      4. 이용계약 종료일로부터 7일 이내에 캐비닛 최종 수거 신청<br />
      5. 회사는 최종 수거된 캐비닛 내부와 외부의 상태를 확인 후 이상이 없음을
      확인<br />
      6. 추가 비용분(연체료, 운송료, 처분/복구 비용, 할인 취소, 피해 보상액 등), 미
      결제분 등을 보증금 또는 선납금과 상계하거나 모바일 앱에 등록된 신용카
      드로 정산/결제한 후 보증금 및 환불금이 이용자에게 반환됨과 동시에 이용
      계약의 해지<br />
      ② 제1항의 해지 절차를 거치지 않는 경우 회원이 이용계약 해지에 따른 원상회복의
      무를 이행하지 않는 것에 해당하므로 이용료가 계속 청구될 수 있습니다.<br />
      ③ 회사는 이용료 미정산 및 손해보상 비용의 부족분에 대하여 이용계약 종료 이후
      에도 이용자에게 별도 청구할 수 있습니다.<br />
      <br />
      제15조 (서비스의 변경 및 중단)<br />
      ① 회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라 서비스를
      변경할 수 있으며, 변경 전에 해당 내용을 서비스 내에 공지합니다. 다만, 버그나
      오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우 또는
      중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.<br />
      ② 회사는 영업양도․분할․합병 등에 따른 영업의 폐지, 콘텐츠 제공의 계약만료, 당
      해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하
      기 어려운 경우에는 서비스를 중단할 수 있습니다. 이 경우 중단일자 30일 이전
      까지 중단일자 및 사유를 회사 홈페이지, 서면 등을 통해 공지하고 제22조 제1항
      또는 제2항의 방법으로 회원에게 통지합니다.<br />
      <br />
      제16조 (광고의 제공)<br />
      ① 회사는 서비스의 운영과 관련하여 서비스 내에 광고를 게재할 수 있습니다. 또한
      수신에 동의한 회원에 한하여 앱푸시, 앱알람, 전자우편, 문자서비스(LMS/SMS)
      등의 방법으로 광고성 정보를 전송할 수 있습니다. 이 경우 회원은 언제든지 수
      신을 거절할 수 있으며, 회사는 회원의 수신 거절 시 광고성 정보를 발송하지 아
      니합니다.<br />
      ② 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나
      서비스에 연결될 수 있습니다. <br />
      ③ 제2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우 해당 영역에서 제공
      하는 서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장
      하지 않으며, 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다. <br />
      <br />
      제17조 (회원에 대한 서비스 이용제한)<br />
      ① 회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한
      경우 서비스 이용을 제한할 수 있습니다.<br />
      ② 회사가 제1항에서 정한 이용제한 조치를 하는 경우에는 다음 각 호의 사항을 회
      원에게 통지합니다.<br />
      1. 이용제한 조치의 사유<br />
      2. 이용제한 조치의 유형 및 기간<br />
      3. 이용제한 조치에 대한 이의신청 방법<br />
      <br />
      제18조 (이용제한 조치에 대한 이의신청 절차)<br />
      ① 회원이 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의 통지를 받은 날
      부터 14일 이내에 불복 이유를 기재한 이의 신청서를 서면, 전자우편 또는 이에
      준하는 방법으로 회사에 제출하여야 합니다.<br />
      ② 회사는 제1항의 이의신청서를 접수한 날부터 14일 이내에 불복 이유에 대하여 서
      면, 전자우편 또는 이에 준하는 방법으로 답변합니다. 다만, 회사는 이 기간 내에
      답변이 어려운 경우에는 그 사유와 처리일정을 통지합니다.<br />
      ③ 회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.<br />
      <br />
      제5장 계약의 해지, 손해배상 및 면책조항 등<br />
      <br />
      제19조 (계약 해지 등)<br />
      ① 회원은 언제든지 서비스 이용을 원하지 않는 경우 회원 탈퇴를 통해 이용계약을
      해지할 수 있습니다. 회원탈퇴로 인해 회원이 서비스 내에서 보유한 서비스 이용
      정보는 개인정보처리방침에 별도의 규정이 없는 한 모두 삭제되어 복구가 불가능
      하게 됩니다.<br />
      ② 회사는 회원이 이 약관에서 금지하는 행위를 하거나 법령을 위반하는 등 본 계약
      을 유지할 수 없는 중대한 사유가 있는 경우에는 서비스 이용을 중지하거나 이용
      계약을 해지할 수 있습니다.<br />
      <br />
      제20조 (손해배상)<br />
      ① 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해
      를 배상할 책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는 그러하지 아
      니 합니다.<br />
      ② 회원이 보관한 물품의 멸실, 훼손시에 회사의 책임이 확인되는 경우에 한해 손해
      를 배상할 수 있는 최고 한도액은 100만원으로 합니다.<br />
      ③ 회사가 개별서비스 제공자와 제휴 계약을 맺고 회원에게 개별서비스를 제공하는
      경우에 회원이 이 개별서비스 이용약관에 동의를 한 뒤 개별서비스 제공자의 고
      의 또는 과실로 인해 회원에게 손해가 발생한 경우에 그 손해에 대해서는 개별서
      비스 제공자가 책임을 집니다. 단, 회사에게 고의 또는 과실이 있는 경우는 그러
      하지 아니합니다.<br />
      <br />
      제21조 (회사의 면책)<br />
      ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없
      는 경우에는 서비스 제공에 관하여 책임을 지지 않습니다.<br />
      ② 회사는 아래 각 호의 사유로 인하여 회원에게 발생한 손해에 대하여 책임을 지지
      않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br />
      1. 물품의 발효, 자연발열, 자연발화, 전기기기/장치의 전기적 사고, 파열 또는
      폭발이 생긴 경우<br />
      2. 회원이 기간을 불문하고 캐비닛 내에 물품을 보관, 적재한 후 도어의 결속
      장치와 자물쇠를 정확하게 잠그지 않은 경우<br />
      3. 회원이 기간을 불문하고 제8조의 의무사항을 위반해 캐비닛을 방치하거나 제12조에 내용에 따른 캐비닛 수거 신청 지연으로 인해 손해가 발생한 경우<br />
      4. 지진 또는 분화가 발생하거나 댐 또는 제방이 터지거나 무너져 물품에 손해
      가 발생하는 경우<br />
      5. 바람, 비, 우박, 모래먼지가 들어옴으로써 물품에 손해가 발생하는 경우<br />
      6. 추위, 서리, 얼음, 눈으로 물품에 손해가 발생하는 경우<br />
      7. 핵연료물질 또는 핵연료 물질에 의하여 오염된 물질의 방사성, 폭발성 그 밖
      의 유해한 특성 또는 이들의 특성에 의한 사고로 물품에 손해에 발생하는
      경우<br />
      8. 위 제6호 이외의 방사선을 쬐는 것 또는 방사능 오염으로 인하여 물품에 손
      해가 발생하는 경우<br />
      9. 회사가 제어할 수 없는 전자파, 전자장(EMF)으로 인하여 물품에 손해가 발
      생하는 경우<br />
      10. 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 사유로 서
      비스 이용이 불가한 경우<br />
      11. 회원의 고의 또는 과실로 인한 서비스 이용의 장애<br />
      12. 다른 회원이 게시한 게시글에 신뢰성·정확성이 부족한 경우<br />
      13. 회원간 또는 회원과 타인간 서비스를 매개로 발생한 거래나 분쟁<br />
      14. 무료로 제공되는 서비스 이용<br />
      15. 회원이 서비스를 이용을 통하여 기대하는 유익이 실현되지 않은 경우<br />
      16. 회원이 계정 비밀번호, 모바일 기기 비밀번호, 오픈마켓 사업자가 제공하는
      비밀번호, 주소지, 연락처, 신용카드 정보 등을 관리하지 않아 회원정보의 유
      출이 발생한 경우<br />
      17. 회원이 본 약관 제8조, 제10조 내지 제12조를 위반한 경우<br />
      18. 서버에 대한 제3자의 불법적인 접속 또는 서버의 불법적인 이용 및 프로그
      램을 이용한 비정상적인 접속 방해<br />
      19. 기타 회사의 귀책사유가 인정되지 않는 사유로써 각 호에 준하는 사유<br />
      ③ 회사는 서비스 이용과 관련하여 회원의 고의 또는 과실로 인하여 회원 자신 또는
      제3자에게 발생한 손해에 대하여는 아무런 책임을 부담하지 않습니다.<br />
      ④ 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에
      대해서 회사는 일체의 책임을 지지 않습니다.<br />
      ⑤ 회사는 회원이 서비스를 이용함으로써 기대되는 결과를 얻지 못하거나 서비스를
      통해 얻은 자료를 이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다. 
      다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br />
      <br />
      제22조 (회원에 대한 통지)<br />
      ① 회사가 회원에게 통지를 하는 경우 회원의 모바일 앱푸쉬 및 알림, 전자우편, 문
      자메시지(SMS/MMS), 전화 등으로 할 수 있습니다.<br />
      ② 회사는 회원 전체에게 통지를 하는 경우 7일 이상 회사 홈페이지 또는 모바일 앱
      내에 게시하거나 서면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.<br />
      <br />
      제23조 (재판권 및 준거법)<br />
      이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원 간에 발생한 분
      쟁으로 소송이 제기되는 경우에는 민사소송법에 따라 관할권을 가지는 법원을 관할
      법원으로 합니다.<br />
      <br />
      제24조 (회원의 고충처리 및 분쟁해결)<br />
      ① 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 서비스
      내 또는 그 연결화면에 안내합니다. <br />
      ② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될
      경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이
      소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 서비스 내 공
      지하거나 제22조 제1항에 따라 통지합니다.<br />
      <br />
      부칙 (2024.4.1)<br />
      이 약관은 2024년 4월 1일부터 시행합니다.
    </>
  );
}

export default ServicePolicyContent1;