import { createActions, handleActions } from "redux-actions";

const initialState = {
  orderTab: false,  // false : 수거, true: 배송
  cDefaultDate: "",
  dDefaultDate: "",
  cSelectedDate: "",
  dSelectedDate: "",
  cHistory: [],
  dHistory: [],
  recentHistory: {},
  cabinetYn: "N",
  terminateDate: "",
};

export const POST_COLL_DEFAULT_DATE = "delivery/order/POST_COLL_DEFAULT_DATE";
export const POST_DEL_DEFAULT_DATE = "delivery/order/POST_DEL_DEFAULT_DATE";
export const POST_COLL_SELECTED_DATE = "delivery/order/POST_COLL_SELECTED_DATE";
export const POST_DEL_SELECTED_DATE = "delivery/order/POST_DEL_SELECTED_DATE";
export const POST_ORDER_TAB = "delivery/order/POST_ORDER_TAB";
export const POST_COLL_DEL_HISTORY_LIST = "delivery/order/POST_COLL_DEL_HISTORY_LIST";
export const POST_CABINET_YN = "delivery/POST_CABINET_YN";
export const POST_TERMINATE_DATE = "terminateService/POST_TERMINATE_DATE";
export const CLEAR_DEL_PERSIST = "delivery/CLEAR_DEL_PERSIST";

const actions = createActions({
  [POST_COLL_DEFAULT_DATE]: () => {},
  [POST_DEL_DEFAULT_DATE]: () => {},
  [POST_COLL_SELECTED_DATE]: () => {},
  [POST_DEL_SELECTED_DATE]: () => {},
  [POST_ORDER_TAB]: () => {},
  [POST_COLL_DEL_HISTORY_LIST]: () => {},
  [POST_CABINET_YN]: () => {},
  [POST_TERMINATE_DATE]: () => {},
  [CLEAR_DEL_PERSIST]: () => {},
});

const deliveryReducer = handleActions({
  [POST_COLL_DEFAULT_DATE]: (state, { payload }) => {
    state.cDefaultDate = payload;

    let newState = {...state};

    return newState;
  },

  [POST_DEL_DEFAULT_DATE]: (state, { payload }) => {
    state.dDefaultDate = payload;

    let newState = {...state};

    return newState;
  },

  [POST_COLL_SELECTED_DATE]: (state, { payload }) => {
    state.cSelectedDate = payload[0];
    state.orderTab = payload[1];

    let newState = {...state};

    return newState;
  },

  [POST_DEL_SELECTED_DATE]: (state, { payload }) => {
    state.dSelectedDate = payload[0];
    state.orderTab = payload[1];

    let newState = {...state};

    return newState;
  },

  [POST_ORDER_TAB]: (state, { payload }) => {
    state.orderTab = payload;

    let newState = {...state};

    return newState;
  },

  [POST_COLL_DEL_HISTORY_LIST]: (state, { payload }) => {
    state.cHistory = payload[0];
    state.dHistory = payload[1];
    state.recentHistory = payload[2];

    let newState = {...state};

    return newState;
  },

  [POST_CABINET_YN]: (state, { payload }) => {
    state.cabinetYn = payload;

    let newState = {...state};

    return newState;
  },

  [POST_TERMINATE_DATE]: (state, { payload }) => {
    state.terminateDate = payload;

    let newState = {...state};

    return newState;
  },

  [CLEAR_DEL_PERSIST]: () => {
    let newState = {
      orderTab: false,  // false : 수거, true: 배송
      cDefaultDate: "",
      dDefaultDate: "",
      cSelectedDate: "",
      dSelectedDate: "",
      cHistory: [],
      dHistory: [],
      recentHistory: {},
      cabinetYn: "N",
      terminateDate: "",
    };

    return newState;
  }
}, initialState);

export default deliveryReducer;