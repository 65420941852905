import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getServiceListAPI } from "../../apis/ServiceAPICalls";
import { TEST_IMAGE } from "../../port/Port";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function MainServiceList({serviceInfo, purchasedInfo, applyInfo}) {

  const navigate = useNavigate();
  const [serviceList, setServiceList] = useState([]);
  const serviceReducer = useSelector(state => state.serviceReducer);
  const memServiceInfo = serviceReducer.serviceInfo;

  const checkServiceDate = val => {
    const y = new Date().getFullYear();
    const m = String(new Date().getMonth() + 1).padStart(2, "0");
    const d = String(new Date().getDay()).padStart(2, "0");
    const now = `${y}-${m}-${d}`;

    return val >= now;
  };

  const moveToDetail = e => {
    const isServiceOk = checkServiceDate(memServiceInfo.service_date_ed);

    if(isServiceOk) {
      Swal.fire({
        text: "현재 사용중인 서비스가 있습니다. 서비스 변경을 신청하시겠습니까?",
        confirmButtonText: "확인",
        showCancelButton: true,
        cancelButtonText: "취소",
        cancelButtonColor: "black",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        if(res.isConfirmed) navigate("/changeService", { state: [serviceInfo, purchasedInfo, applyInfo] });
      });
      return;
    } else {
      const service_seq = e.currentTarget.getAttribute("value");
      const serviceInfo = serviceList.filter(item => item.seq === Number(service_seq));

      navigate(`/serviceDetail/${service_seq}`, { state: serviceInfo[0] });
    }
  };

  useEffect(() => {
    // api 요청해서 service list 가져오기
    if(!serviceList.length) {
      (async () => {
        setServiceList(await getServiceListAPI());
      })();
    }
  }, []);

  return (
    <div className="services">
      {
        serviceList.map(item => {

          return (
            <a key={item.seq} onClick={moveToDetail} className="service" title="상세보기" value={item.seq}>
              {
                "Y" === item.is_best ?
                <div className="best">BEST</div>
                :
                <></>
              }
              <div className="col1">
                {/* 나중에 이미지 서버로 부터 받아온 이미지로 수정해야함 */}
                <img src={`${TEST_IMAGE}/goods/${item.filename_svr}`} className="imgItem" />
              </div>
              <div className="col2">
                <div className="txt1">{item.title}</div>
                <div className="txt2" dangerouslySetInnerHTML={{__html: item.summary}}></div>
                <br/>
                {0 !== item.discount_price && item.discount_price !== item.regular_price ? <div className="originalPrice">정상가격: 월 {Number(item.regular_price).toLocaleString()}원</div> : <br/>}
                <div className="row">
                  {0 !== item.discount_price && item.discount_price !== item.regular_price ? <div className="discount">{Math.round((item.discount_price/item.regular_price) * 100)}%<span className="hide">할인</span></div> : <></>}
                  <div className="price">월 {item.discount_price !== item.regular_price ? Number(item.regular_price - item.discount_price).toLocaleString() : item.regular_price.toLocaleString()}원 (4주)</div>
                </div>
                <div className="icon1"></div>
              </div>
            </a>
          );
        })
      }
    </div>
  );
}

export default MainServiceList;