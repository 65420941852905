import { useEffect, useState } from "react";
import DeliveryHeader from "../../components/delivery/DeliveryHeader";
import DeliveryHistory from "../../components/delivery/DeliveryHistory";
import DeliveryTop from "../../components/delivery/DeliveryTop";
import MainMenu from "../../components/main/MainMenu";
import { getMemberCabinetListAPI, getMemberInfoAPI, getMemberPaymentListAPI, getMemberPurchasedServiceListAPI, getMemberStorageListAPI } from "../../apis/MemberAPICalls";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_MEMBER_INFO, GET_PAYMENT_GOODS_SEQ, GET_SERVICE_SEQ, POST_MEMBER_COUPON_CNT, POST_MEMBER_DEFAULT_PAYMENT_INFO, POST_MEMBER_SERVICE_AVAILABLE } from "../../modules/member/MemberModule";
import RegionAlertPopup from "../../components/popup/RegionAlertPopup";
import DeliveryCompleteModal from "../../components/modal/DeliveryCompleteModal";
import { getHolidayListAPI } from "../../apis/DeliveryAPICalls";
import { POST_CABINET_YN, POST_COLL_DEL_HISTORY_LIST } from "../../modules/delivery/DeliveryModule";
import { POST_MEMBER_PURCHASED_SERVICE_INFO } from "../../modules/member/ServiceModule";
import Swal from "sweetalert2";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";

function Delivery() {

  const [purchasedList, setPurchasedList] = useState([]);
  const [applyList, setApplyList] = useState([]);
  const [cabinetList, setCabinetList] = useState([]);
  const [memInfo, setMemInfo] = useState({});
  const [rModalStatus, setRModalStatus] = useState(false);
  const [dcModalStatus, setDCModalStatus] = useState("");
  const [holidayList, setHolidayList] = useState([]);
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberReducer = useSelector(state => state.memberReducer);

  const moveToOrder = () => {
    if("0" === mem_seq) {
      Swal.fire({
        text: "회원가입 후 이용해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => navigate("/login"));
      return;
    }

    if(!purchasedList.length) {
      Swal.fire({
        text: "사용중인 서비스가 없습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => navigate("/"));
      return;
    }

    if(!Object.keys(memberReducer.cardInfo).length) {
      Swal.fire({
        text: "등록된 결제카드가 없습니다. 카드를 등록하시겠습니까?",
        confirmButtonText: "카드 등록하기",
        showCancelButton: true,
        cancelButtonText: "취소",
        cancelButtonColor: "black",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        if(res.isConfirmed) navigate("/mypage/paymentManage/registPayment");
      })
      .catch(err => console.error(err));
      return;
    } 

    if(applyList.length && purchasedList[0].payment_goods_seq === applyList[0].goods_seq) {
      if("C2" !== applyList[0].status && "D1" !== applyList[0].status && "E1" !== applyList[0].status) {
        Swal.fire({
          html: "현재 수거/배송 작업이 진행중입니다.<br/>작업이 완료된 후 다시 시도해주세요.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then();
        return;
      }
    }

    if(!memberReducer.coll_addr_available) {
      Swal.fire({  
        html: "현재 등록된 수거지는 서비스 불가능 지역입니다.<br/>주소를 재설정한 후 신청해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    } else if(!memberReducer.del_addr_available) {
      Swal.fire({  
        html: "현재 등록된 배송지는 서비스 불가능 지역입니다.<br/>주소를 재설정한 후 신청해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if(!purchasedList[0].cabinet_seq) navigate("order/selectDate?type=D&cabinet=N");
    else navigate("order");
  };

  useEffect(() => {
    ScrollToTop();

    if(!sessionStorage.getItem("mem_seq")) {
      // navigate("/login");
      window.location.replace("/login");
      return;
    }

    if(!holidayList.length) {
      (async () => {
        setHolidayList(await getHolidayListAPI());
      })();
    }

    if(!Object.keys(memInfo).length) {
      (async () => {
        setMemInfo(await getMemberInfoAPI(mem_seq));
      })();
    }

    (async () => {
      setPurchasedList(await getMemberPurchasedServiceListAPI(mem_seq));
      setApplyList(await getMemberStorageListAPI(mem_seq));
      setCabinetList(await getMemberCabinetListAPI(mem_seq));
      dispatch({ type: POST_MEMBER_DEFAULT_PAYMENT_INFO, payload: (await getMemberPaymentListAPI(decrypt(sessionStorage.getItem("mem_seq")))).filter(item => "Y" === item.default_yn)[0] });
    })();
  }, []);

  useEffect(() => {
    if(memInfo && Object.keys(memInfo).length) {
      dispatch({ type: GET_MEMBER_INFO, payload: memInfo });
      // if(!memInfo.collection_addr1 || !memInfo.delivery_addr1) setRModalStatus(true);
      if(memInfo.collection_addr1 && memInfo.delivery_addr1) {
        if(((memInfo.collection_addr1).includes("서울") || ((memInfo.collection_addr1).includes("경기") && ((memInfo.collection_addr1).includes("하남") || (memInfo.collection_addr1).includes("구리")))) && ((memInfo.delivery_addr1).includes("서울") || ((memInfo.delivery_addr1).includes("경기") && ((memInfo.delivery_addr1).includes("하남") || (memInfo.delivery_addr1).includes("구리"))))) {
          dispatch({ type: POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: true, del: true}});
        } else if(((memInfo.collection_addr1).includes("서울") || ((memInfo.collection_addr1).includes("경기") && ((memInfo.collection_addr1).includes("하남") || (memInfo.collection_addr1).includes("구리")))) && (!(memInfo.delivery_addr1).includes("서울") && (!(memInfo.delivery_addr1).includes("경기") || (!(memInfo.delivery_addr1).includes("하남") && !(memInfo.delivery_addr1).includes("구리"))))) {
          dispatch({ type: POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: true, del: false}});
        } else if((!(memInfo.collection_addr1).includes("서울") && (!(memInfo.collection_addr1).includes("경기") || (!(memInfo.collection_addr1).includes("하남") && !(memInfo.collection_addr1).includes("구리")))) && ((memInfo.delivery_addr1).includes("서울") || ((memInfo.delivery_addr1).includes("경기") && ((memInfo.delivery_addr1).includes("하남") || (memInfo.delivery_addr1).includes("구리"))))) {
          dispatch({ type: POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: false, del: true}});
        } else {
          dispatch({ type: POST_MEMBER_SERVICE_AVAILABLE, payload: {coll: false, del: false}});
        }
      }
    }
  }, [memInfo]);

  useEffect(() => {
    if(purchasedList.length) {
      dispatch({ type: GET_PAYMENT_GOODS_SEQ, payload: purchasedList[0].payment_goods_seq });
      dispatch({ type: GET_SERVICE_SEQ, payload: purchasedList[0].seq });
      dispatch({ type: POST_MEMBER_PURCHASED_SERVICE_INFO, payload: purchasedList[0] });

      if(purchasedList[0].cabinet_seq) dispatch({ type: POST_CABINET_YN, payload: "Y" });
      else dispatch({ type: POST_CABINET_YN, payload: "N" });
      /* Coupon data 추가되면 활성화 */
      dispatch({ type: POST_MEMBER_COUPON_CNT, payload: purchasedList[0].coupon_times });
    }
  }, [purchasedList]);

  useEffect(() => {
    if(applyList.length) {
      dispatch({ type: POST_COLL_DEL_HISTORY_LIST, payload: [applyList.filter(item => "C" === item.type), applyList.filter(item => "D" === item.type), applyList[0]] });
    }
  }, [applyList]);

  return (
    <>
      <DeliveryHeader cabinetInfo={cabinetList[0]}/>

      <RegionAlertPopup modalStatus={rModalStatus} setModalStatus={setRModalStatus}/>
      {
        applyList.length ?
        <DeliveryCompleteModal applyList={applyList} status={dcModalStatus} setStatus={setDCModalStatus}/>
        :
        <></>
      }
      
      <div className="contents deliveryContents">
        <div className="centerWrap">

          <DeliveryTop purchasedList={purchasedList} applyList={applyList} setStatus={setDCModalStatus}/>
          <DeliveryHistory memInfo={memInfo} applyList={applyList}/>
          <MainMenu/>

          {/* {
            purchasedList.length ?
            // <button tabIndex={0} className="bottomFixedButton" onClick={moveToOrder}><span>수거/배송 신청하기</span></button>
            <>
              {
                applyList.length ?
                <>
                  {
                    purchasedList[0].payment_goods_seq === applyList[0].goods_seq ?
                    <>
                      {
                        ("C2" === applyList[0].status || "D1" === applyList[0].status || "E1" === applyList[0].status) ?
                        <button tabIndex={0} className="bottomFixedButton" onClick={moveToOrder}><span>수거/배송 신청하기</span></button>
                        :
                        <></>
                      }
                    </>
                    :
                    <button tabIndex={0} className="bottomFixedButton" onClick={moveToOrder}><span>수거/배송 신청하기</span></button>
                  }
                </>
                :
                <button tabIndex={0} className="bottomFixedButton" onClick={moveToOrder}><span>수거/배송 신청하기</span></button>
              }
            </>
            :
            <></>
          } */}
          <button tabIndex={0} className="bottomFixedButton" onClick={moveToOrder}><span>수거/배송 신청하기</span></button>
          <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로"></button>
        </div>
      </div>
    </>
  );
}

export default Delivery;