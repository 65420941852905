import $ from "jquery";
import ServicePolicyContent1 from "../../js/ServicePolicyContent1";
import ServicePolicyContent2 from "../../js/ServicePolicyContent2";

function ServicePolicyModal({status, setStatus, type, setType, data, setData}) {
  
  const closeModal = () => {
    setStatus(false);
    setType(0);
    $("#modalContent").scrollTop(0);
  };

  const agreePolicy = () => {
    const key = "policy" + type;
    console.log(key);

    setData({
      ...data,
      [key]: "Y"
    });
    setStatus(false);
    setType(0);
    $("#modalContent").scrollTop(0);
  };

  return (
    <>
      <div className={status ? "modal bigModal active" : "modal bigModal"}>
        <div className="modalHeader">
          <div className="modalTitle">
            {
              "1" === type && "스토르앤고 이용약관"
            }
            {
              "2" === type && "스토르앤고 개인정보처리방침"
            }
            {
              "3" === type && "마케팅 정보 수신 동의"
            }
          </div>
          <button tabIndex={0} className="closeModal" title="모달 닫기" onClick={closeModal}></button>
        </div>

        <div id="modalContent" className="modalContent" style={{ overflowY: "scroll", maxHeight: "calc(100vh - 554px", padding: "0 17px" }}>
          <div className="policyContent active">
            {
              "1" === type && <ServicePolicyContent1/>
            }
            {
              "2" === type && <ServicePolicyContent2/>
            }
          </div>
        </div>
        <button tabIndex={0} className="buttonDone" title="동의" onClick={agreePolicy}>동의하기</button>
      </div>

      <button tabIndex={0} className={status ? "dimm active" : "dimm"} title="닫기" onClick={closeModal}></button>
    </>
  );
}

export default ServicePolicyModal;