function ServicePolicyContent2() {

  return (
    <>
      스토르앤고 (STORR&Go) 개인정보 처리방침<br />
      <br/>
      스토리지엠 주식회사(이하 ‘회사’)는 관련 법령에 따라 스토르앤고‘STORR&Go’(이하 ‘서비
      스’)를 이용하는 이용자의 개인정보를 보호하고, 이와 관련한 고충을 신속하고 원활하게 처리
      하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br/>
      <br />
      제1조(개인정보의 처리 목적)<br />
      회사는 다음의 목적을 위하여 개인정보를 처리합니다. <br />
      1. 회원 가입 및 관리: 회원가입의사 확인, 본인 식별인증, 연령인증, 회원자격 유지 및 관
      리, 소속된 단체, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대
      리인의 동의여부 확인, 각종 고지·통지<br />
      2. 재화 또는 서비스 제공: 보관물품을 포함한 캐비닛의 수거 또는 배송, 기본/맞춤형 서
      비스 제공, 계약서·청구서 발송, 본인인증, 연령인증, 서비스 요금결제 및 정산, 채권
      추심<br />
      3. 고충처리: 이용자의 신원 확인, 고충사항 확인, 사실조사를 위한 연락·통지, 처리결과
      통보<br />
      4. 마케팅 및 광고에의 활용: 맞춤형 광고 제공, 이벤트 및 광고성 정보 제공 및 참여기회
      제공<br />
      5. 서비스 개선 및 개발: 기존 서비스 개선 및 신규 서비스, 맞춤형 서비스 개발<br />
      6. 가명정보의 활용: 통계작성, 과학적 연구, 공익적 기록보존 등을 위한 가명처리 및 가
      명정보의 활용<br />
      <br />
      제2조(처리하는 개인정보 항목)<br />
      회사는 서비스 이용자에 대하여 다음의 개인정보항목을 수집하여 처리하고 있습니다. <br/>
      1. 서비스 전용 모바일 앱 및 구매 채널에 따라 회원 가입 및 서비스 신청 단계에서 서비
      스 제공을 목적으로 이용자로부터 제공받는 사항(필수항목): 이름, 이메일 주소, 휴대
      폰(또는 전화) 번호, 신용카드 정보, 수거지 및 배송지 주소, 주소지 공동현관 비밀번
      호, 주소지 엘리베이터 운영 정보, 주소지 운송차량 출입을 위한 사전 등록 정보 및 주
      정차 시설 정보, 캐비닛(또는 자물쇠) 비밀번호, 서비스 결제 이력, 수거 및 배송 이력, 구매채널의 아이디, 구매채널의 결제 정보 및 결제 이력, 구매채널의 운송 정보 등<br/>
      2. 서비스 이용 시 기본수집사항(필수항목): 이름, 이메일 주소, 휴대폰 번호, 신용카드
      정보, 수거지 주소, 배송지 주소, 공동현관 비밀번호, 배송지 주정차 정보, 보관센터 지
      점, 캐비닛 시리얼넘버, 캐비닛 비밀번호, 이용 기간, 서비스 결제 이력, 수거 및 배송
      이력 등<br />
      3. 서비스 이용 시 추가수집사항(선택항목): 생년월일, 성별, 직업, 주거형태, 보관물품
      종류 등 마케팅에 필요한 내용<br />
      4. 고충처리 시: 이용자로부터 위 각 정보 중 필요한 항목 및 해당 고충처리에 필요한 별
      개 항목을 수집 및 처리<br />
      <br/>
      제3조(개인정보의 처리 및 보유기간)<br />
      ① 회사는 이용자가 서비스를 탈퇴 또는 이용자격을 상실할 경우에는 별도의 요청이 없더라
      도 수집된 이용자의 정보를 지체없이 삭제 및 파기합니다. 다만, 회원 탈퇴 또는 이용자격
      상실에도 불구하고 다음의 정보에 대해서는 아래의 이유로 보존합니다.<br />
      1. 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까
      지<br />
      2. 서비스 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지<br />
      3. 회사가 이용약관에 따라 서비스 이용계약을 해지한 경우 부정한 재가입 및 서비스 이
      용을 방지하기 위하여 서비스 부정이용 기록을 해지 후 1년간 보존<br />
      ② 전항에도 불구하고 회사는 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지 보존
      합니다. <br />
      1. 서비스 이용 관련 개인정보 (로그기록): 「통신비밀보호법」상 보존기간인 3개월<br />
      2. 계약 또는 청약철회 등에 관한 기록 및 대금결제 및 재화 등의 공급에 관한 기록: 「전
      자상거래 등에서의 소비자보호에 관한 법률」상 보존기간인 5년<br />
      3. 소비자의 불만 또는 분쟁처리에 관한 기록: 「전자상거래 등에서의 소비자보호에 관
      한 법률」상 보존기간인 3년<br />
      4. 표시 광고에 관한 기록: 「전자상거래 등에서의 소비자보호에 관한 법률」상 보존기
      간인 6개월<br />
      5. 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류: 「국세기본법」상 보존기간인
      5년<br />
      ③ 회사는 1년 또는 이용자가 별도로 정한 기간 동안 서비스를 이용하지 않은 이용자의 개인 정보를 별도로 개인정보를 분리보관 또는 삭제하여 관리합니다.<br />
      <br />
      제4조(개인정보의 제3자 제공)<br />
      ① 회사는 이용자의 동의를 받거나 개인정보 보호법 또는 다른 법률의 특별한 규정이 있는 경우에만 개인정보를 제3자에게 제공합니다.<br/>
      <br />
      제5조(수집목적과 합리적으로 관련된 범위 내의 개인정보 이용 및 제공)<br />
      회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을 고려하여, 이용자의 동의 없이
      개인정보를 이용 또는 제3자에게 제공할 수 있습니다. <br />
      1. 당초 수집 목적과 관련성이 있는지 여부: 당초 수집 목적과 추가적 이용·제공 목적이 성
      질이나 경향에 있어 연관이 있는지 등을 고려하여 따라 판단<br />
      2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는
      제공에 대한 예측 가능성이 있는지 여부: 개인정보처리자와 이용자 간의 관계, 기술 수
      준 및 발전 속도, 상당한 기간동안 정립된 일반적인 사정(관행) 등을 고려하여 판단<br />
      3. 이용자의 이익을 부당하게 침해하는지 여부: 추가적인 이용 목적과의 관계에서 이용자
      의 이익이 실질적으로 침해되는지 및 해당 이익 침해가 부당한지 등을 고려하여 판단<br />
      4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부: 침해 가능성을
      고려한 안전 조치가 취해지는지 등을 고려하여 판단<br/>
      <br />
      제6조(이용자와 법정대리인의 권리·의무 및 행사방법)<br />
      ① 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행
      사할 수 있습니다.<br />
      ② 제1항에 따른 권리 행사는 서면, 전자우편 등을 통하여 할 수 있으며, 회사는 이에 대해 지
      체 없이 이행합니다.<br />
      ③ 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통해서
      할 수 있습니다. 이 경우 수임인에 대한 위임사실을 확인할 수 있는 위임장을 제출해야 합
      니다.<br />
      ④ 개인정보 보호법 등 관계 법령에서 정하는 바에 따라 이용자의 개인정보 열람·정정·삭제·
      처리정지 요구 등의 권리 행사가 제한될 수 있습니다.<br />
      ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있
      는 경우에는 그 삭제를 요구할 수 없습니다.<br />
      ⑥ 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br />
      <br />
      제7조(개인정보의 파기) <br />
      ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에
      는 지체없이 해당 개인정보를 파기합니다. <br/>
      ② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불
      구하고 제3조 제2항에 기재된 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 
      해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니
      다. <br/>
      ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. <br/>
      1. 파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책
      임자의 승인을 받아 개인정보를 파기합니다. <br/>
      2. 파기방법: 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도
      록 기술적 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로
      분쇄하거나 소각하여 파기합니다. <br/>
      ④ 회사는 1년 또는 이용자가 별도로 동의한 기간 동안 서비스를 이용하지 않은 이용자는 휴
      면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된 개인정보
      는 4년간 보관 후 지체없이 파기합니다. <br/>
      ⑤ 휴면계정으로 전환을 원치 않으시는 경우, 휴면계정 전환 전 서비스 로그인을 하시면 됩니
      다. 또한 휴면계정으로 전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면
      계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.<br/>
      <br />
      제8조(개인정보의 안전성 확보조치)<br />
      회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <br />
      1. 관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육 등<br />
      2. 기술적 조치: 해킹 등에 대비한 기술적 대책, 개인정보의 암호화, 개인정보처리시스템
      의 접근권한 관리, 접속기록의 보관 및 위변조 방지 등<br />
      3. 물리적 조치: 서버실, 자료보관실 등의 접근통제<br/>
      <br />
      제9조(개인정보 보호책임자) <br />
      ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 이와 관련한 이용자의 불만처리
      및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <br />
      개인정보 보호책임자<br />
      - 성명: 박건태<br />
      - 직책: 대표이사<br />
      - 연락처: 010-2170-4670, ktpark@storr.kr<br />
      ② 이용자는 회사의 서비스(또는 사업)을 이용하면서 발생한 모든 개인정보 보호 관련 문의, 
      불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 아래 담당부서로 문의할
      수 있습니다. <br />
      - 부서명: 스토리지엠㈜ 대표이사<br />
      - 연락처: 010-2170-4670, ktpark@storr.kr<br/>
      <br />
      제10조(영상정보처리기기 설치·운영)<br />
      회사는 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.<br />
      1. 영상정보처리기기 설치근거·목적: 회사의 시설안전·도난방지·화재예방·무인경비 등<br />
      2. 설치 대수, 설치 위치, 촬영 범위: 보관센터의 출입구, 내부 공간 등 주요시설물에 지점
      당 2대 이상 설치, 촬영범위는 주요시설물의 전 공간을 촬영<br />
      3. 관리책임자, 담당부서 및 영상정보에 대한 접근권한자: 대표이사, 보안관리자 및 ADT
      캡스<br />
      4. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법<br />
      - 촬영시간: 24시간 촬영<br />
      - 보관기간: 촬영시부터 30일<br />
      - 보관장소 및 처리방법: ADT캡스영상정보처리기기 통제실에 보관·처리<br />
      5. 영상정보 확인 방법 및 장소: 관리책임자에 요구<br />
      6. 이용자의 영상정보 열람 등 요구에 대한 조치: 개인영상정보 열람·존재확인 청구서로
      신청하여야 하며, 이용자 자신이 촬영된 경우 또는 명백히 이용자의 생명.신체.재산 이
      익을 위해 필요한 경우에 한해 열람을 허용함<br />
      7. 영상정보 보호를 위한 기술적.관리적.물리적 조치: 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장·전송기술 적용, 처리기록보관 및 위·변조 방
      지조치, 보관시설 마련 및 잠금장치 설치 등<br/>
      <br />
      제11조(개인정보 처리방침의 변경)<br />
      회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정
      할 수 있습니다. 개인정보처리방침이 변경되는 경우 회사는 변경 사항 최소한 효력발생일 7
      일 이전에 게시하며, 변경된 개인정보처리방침은 기재된 효력발생일에 그 효력이 발생합니
      다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경
      이 발생할 때에는 최소 30일 전에 미리 알려드리겠습니다.<br />
      <br />
      이 개인정보 처리방침은 2024. 4. 1.부터 적용됩니다.
    </>
  );
}

export default ServicePolicyContent2;