import { useEffect, useState } from "react";
import { getNoticeListAPI } from "../../apis/NoticeAPICalls";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../js/ScrollToTop";
import { TEST_IMAGE } from "../../port/Port";

function NoticeComp() {

  const [list, setList] = useState([]);
  const navigate = useNavigate();

  const moveBack = () => {
    navigate("/mypage", {replace: true});
  };

  const dateFormat = date => {
    const year = date.substr(0, 4);
    const month = date.substr(5, 2);
    const day = date.substr(8, 2);
    
    return `${year}년 ${month}월 ${day}일`;
  };

  const moveToNoticeDetail = e => {
    const notice_seq = e.target.getAttribute("value");
    const info = list.filter(item => item.seq === Number(notice_seq));
    
    navigate(`detail/${notice_seq}`, { state: notice_seq });
  };

  useEffect(() => {
    ScrollToTop();

    if(!list.length) {
      (async () => {
        setList(await getNoticeListAPI());
      })();
    }
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>공지사항</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="noticeWrap">
            {/* notice list */}
            {
              list.map(item => {
                return (
                  <>
                    <div key={item.seq} className="noticeOne" value={item.seq} style={{ cursor: "pointer" }} onClick={moveToNoticeDetail}>
                      <div className="noticeTitle" value={item.seq}>{item.title}</div>
                      <div className="noticeDate" value={item.seq}>{dateFormat(item.regdate)}</div>
                    </div>
                  </>
                );
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default NoticeComp;