import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './Store';
import { PersistGate } from 'redux-persist/integration/react';
// import { store } from './Store';
// import { persistStore } from 'redux-persist';

const root = ReactDOM.createRoot(document.getElementById('root'));

// export let persistor = persistStore(store);
root.render(
  <Provider store={ store }>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
