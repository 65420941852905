import { useNavigate } from "react-router-dom";
import MainHeader from "../main/MainHeader";
import { useEffect, useState } from "react";
import { getMemberPayHistoryAPI, getMemberPaymentListAPI, getMemberPurchasedServiceListAPI, getMemberStorageListAPI } from "../../apis/MemberAPICalls";
import MainMenu from "../main/MainMenu";
import { getDetailServiceAPI } from "../../apis/ServiceAPICalls";
import PriceToString from "../../js/PriceToString";
import { useSelector, useDispatch } from "react-redux";
import GradeModal from "../modal/GradeModal";
import ConfirmCancelServiceModal from "../modal/ConfirmCancelServiceModal";
import { GET_SERVICE_SEQ } from "../../modules/member/MemberModule";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";
import Swal from "sweetalert2";
import EncryptCardNum from "../../js/EncryptCardNum";

function HistoryComp() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [purchasedList, setPurchasedList] = useState([]);
  const [serviceInfo, setServiceInfo] = useState({});
  const [cardInfo, setCardInfo] = useState({});
  const [applyList, setApplyList] = useState([]);
  const [gradeModalStatus, setGradeModalStatus] = useState(false);
  const [csModalStatus, setCsModalStatus] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  const memberReducer = useSelector(state => state.memberReducer);
  const deliveryReducer = useSelector(state => state.deliveryReducer);
    
  const dateFormat = val => {
    const year = val.substring(0, 4);
    const month = val.substring(5, 7).replace(".", "").padStart(2, "0");
    const date = val.substring(8, 10).replace(".", "").padStart(2, "0");
    let day = new Date(val).getDay();
    
    if(1 === day) {
      day = "월";
    } else if(2 === day) {
      day = "화";
    } else if(3 === day) {
      day = "수";
    } else if(4 === day) {
      day = "목";
    } else if(5 === day) {
      day = "금";
    } else if(6 === day) {
      day = "토";
    } else if(0 === day) {
      day = "일";
    }

    return `${year}. ${month}. ${date} (${day})`;
  };

  const dateFormat2 = date => {
    let formated = new Date(date);
    // formated.setDate(formated.getDate() + 1);
      
    const edDate = formated.toLocaleString();
    const year = edDate.substring(0, 4);
    const month = edDate.substring(6, 8).replace(".", "").padStart(2, "0");
    const day = edDate.substring(9, 11).replace(".", "").padStart(2, "0");
    
    return `${year}년 ${month}월 ${day}일`;
  };
  
  const dateFormat3 = val => {
    const y = val.substring(0, 4);
    const m = val.substring(5, 7);
    const d = val.substring(8, 10);

    return `${y}. ${m}. ${d}`;
  }

  const getDateDiff = val => {
    const now = new Date();
    const date = new Date(val);

    const diff = now.getTime() - date.getTime();

    return Math.floor(Math.abs(diff / (1000 * 60 * 60 * 24)));
  };

  const cardFormat = val => {
    const first = val.substring(0, 4);
    const last = val.substring(12);

    return `${first}-****-****-${last}`;
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const moveToPaymentHistory = () => {
    navigate("paymentHistory", { state: [serviceInfo, purchasedList[0]] });
  };

  const moveToDeliveryHistory = () => {
    navigate("deliveryHistory", { state: applyList });
  };

  const moveToChangeService = () => {
    if(!memberReducer.coll_addr_available) {
      Swal.fire({
        html: "현재 등록된 수거지는 서비스 불가능 지역입니다.<br/>주소를 재설정한 후 서비스 변경을 신청해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    } else if(!memberReducer.del_addr_available) {
      // alert("현재 등록된 배송지는 서비스 불가능 지역입니다. 주소를 재설정한 후 서비스 변경을 신청해주세요.");
      Swal.fire({
        
        html: "현재 등록된 배송지는 서비스 불가능 지역입니다.<br/>주소를 재설정한 후 서비스 변경을 신청해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }
    navigate("/changeService", { state: [serviceInfo, purchasedList[0], applyList[0]] });
  };

  const moveToPaymentList = () => {
    navigate("/mypage/paymentManage");
  };

  const openGradeModal = () => {
    setGradeModalStatus(true);
  };

  const openCsModal = () => {
    setCsModalStatus(true);
  };

  const memberWithdraw = () => {
    setCsModalStatus("withdraw");
  };

  const compareDate = val => {
    const today = new Date();
    const comparison = new Date(val);

    return today.toLocaleDateString() < comparison.toLocaleDateString();
  };

  const btw3m = val => {
    const comp = new Date();
    comp.setMonth(comp.getMonth() - 3);
    comp.setHours(0);
    comp.setMinutes(0);
    comp.setSeconds(0);
    const regdate = new Date(val);
    regdate.setHours(0);
    regdate.setMinutes(0);
    regdate.setSeconds(0);

    return regdate >= comp;
  };

  const checkAutoPayDate = val => {
    const now = new Date();
    const edDate = new Date(val);
    edDate.setDate(edDate.getDate() + 1);
    edDate.setHours(0);
    edDate.setMinutes(0);
    edDate.setSeconds(0);

    return now < edDate;
  };

  useEffect(() => {
    ScrollToTop();

    if(!sessionStorage.getItem("mem_seq")) window.location.replace("/login");;

    (async () => {
      setPurchasedList(await getMemberPurchasedServiceListAPI(mem_seq));
      setApplyList(await getMemberStorageListAPI(mem_seq));
    })();

    if(!Object.keys(cardInfo).length && mem_seq) {
      (async () => {
        const defaultCard = (await getMemberPaymentListAPI(mem_seq)).filter(item => "Y" === item.default_yn)[0];
        
        if(defaultCard) setCardInfo(defaultCard);
        else setCardInfo({});
      })();
    }

    (async () => {
      if(!historyList.length) {
        const list = await getMemberPayHistoryAPI(decrypt(sessionStorage.getItem("mem_seq")));
        const arr = list.filter(item => btw3m(item.regdate));

        setHistoryList(arr);
      }
    })();
  }, []);

  useEffect(() => {
    if(!Object.keys(serviceInfo).length && purchasedList.length) {
      (async () => {
        setServiceInfo(await getDetailServiceAPI(purchasedList[0].payment_goods_seq));
      })();
    }

    if(purchasedList.length) {
      dispatch({ type: GET_SERVICE_SEQ, payload: purchasedList[0].seq });
    }
  }, [purchasedList]);

  return (
    <>
      <MainHeader/>

      <GradeModal status={gradeModalStatus} setStatus={setGradeModalStatus}/>
      <ConfirmCancelServiceModal status={csModalStatus} setStatus={setCsModalStatus} purchasedInfo={purchasedList[0]} type={applyList.length && applyList[0].apply_date && ("A1" === applyList[0].status || "B1" === applyList[0].status || "C1" === applyList[0].status) ? compareDate(applyList[0].apply_date) : false}/>

      <div className="contents deliveryContents">
        <div className="centerWrap">
          <div className="titleWrap2">
            <div className="title">이용내역</div>
          </div>

          <div className="box1">
            {
              !purchasedList.length || !Object.keys(serviceInfo).length || "Y" === purchasedList[0].refund_yn || !checkAutoPayDate(purchasedList[0].service_date_ed) ?
              <div className="noList">
                <img src={`${process.env.PUBLIC_URL}/assets/images/nolist1.png`} alt=""/>
                <div className="txt">현재 이용중인 서비스가 없습니다.</div>
              </div>
              :
              <div className="service">
                <div className="row">
                  {/* 사용자 등급 정보 불러와서 표시해줘여함. */}
                  <button className="status silver" onClick={openGradeModal}>
                    {
                      1 === memberReducer.memInfo.rate_seq && "BRONZE"
                    }
                    {
                      2 === memberReducer.memInfo.rate_seq && "SILVER"
                    }
                    {
                      3 === memberReducer.memInfo.rate_seq && "GOLD"
                    }
                    {
                      4 === memberReducer.memInfo.rate_seq && "DIAMOND"
                    }
                  </button>
                </div>

                <div className="row row2">
                  <div className="date2">{dateFormat(purchasedList[0].regdate)} 부터 <span className="t1">{getDateDiff(purchasedList[0].regdate.substring(0, 10))}일</span> 동안 소중하게 보관중</div>
                </div>
                
                <div className="serviceNow">
                  <div className="row">
                    <div className="serviceNowTitle type2">{serviceInfo.title}</div>
                    {
                      purchasedList.length /*&& purchasedList[0].cabinet_seq*/ ?
                      <button tabIndex={0} className="button button1 scBtn" onClick={moveToChangeService}>서비스 변경 및 해지</button>
                      :
                      <></>
                    }
                  </div>
                  <div className="row row4">
                    <div className="servicePrice">월(4주) 구독요금 {PriceToString(serviceInfo.regular_price - serviceInfo.discount_price)}원</div>
                  </div>
                </div>

                <div className="row row5">
                  <div className="payment">{Object.keys(cardInfo).length ? `신용카드 : ${cardFormat(cardInfo.card_num)}` : ``}</div>
                  {/* <a className="viewMore" onClick={moveToPaymentHistory}>결제상세</a> */}
                </div>
                <div className="row row6">다음 자동 결제일은 {dateFormat2(purchasedList[0].service_date_ed)} 입니다.</div>

                <button tabIndex={0} className="buttonChangePayment" onClick={moveToPaymentList}>결제 신용카드 관리</button>
              </div>
            }
          </div>

          <div className="row">
            {
              purchasedList.length && (("N" === deliveryReducer.cabinetYn && (!applyList.length || (!("D" === applyList[0].type && ("A1" === applyList[0].status || "B1" == applyList[0].status || "D1" === applyList[0].status))) && "F1" === applyList[0].free_yn)) && checkAutoPayDate(purchasedList[0].service_date_ed)) ?
              <button className="buttonCancelPayment" onClick={openCsModal}>첫 배송 전 결제 취소하기</button>
              :
              <></>
            }
          </div>

          <div className="titleWrap2 titleWrap2a">
            {/* <div className="title">최근 수거/배송내역</div> */}
            <div className="title">최근 결제내역</div>
            {/* <a onClick={moveToDeliveryHistory} className="viewMore">더 보기</a> */}
            <a onClick={moveToPaymentHistory} className="viewMore">더 보기</a>
          </div>

          {/* <div className="histories">
            {
              !applyList.length ?
              <div className="noList">
                <div className="txt">수거/배송 내역이 없습니다.</div>
              </div>
              :
              applyList.map(item => {
                return (
                  <div key={item.seq} className="history">
                    <div className="row">
                      <div className="date">{dateFormat3(item.regdate)}</div>
                      <div className="payment">
                        {
                          ("F1" === item.free_yn || "F2" === item.free_yn) ? "무료" : "F3" === item.free_yn ? "쿠폰" : "추가결제"
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="status">
                        {
                          "C" === item.type && ("A1" === item.status || "B1" === item.status) && "수거 신청"
                        }
                        {
                          "C" === item.type && "C2" === item.status && "수거 취소"
                        }
                        {
                          "C" === item.type && "D1" === item.status && "수거 완료"
                        }
                        {
                          "C" === item.type && "E1" === item.status && "수거 실패"
                        }
                        {
                          "D" === item.type && ("A1" === item.status || "B1" === item.status) && "배송 신청"
                        }
                        {
                          "D" === item.type && "C2" === item.status && "배송 취소"
                        }
                        {
                          "D" === item.type && "D1" === item.status && "배송 완료"
                        }
                        {
                          "D" === item.type && "E1" === item.status && "배송 실패"
                        }
                      </div>
                      <div className="amount free">
                        {
                          "D" === item.type && "F1" === item.free_yn && "첫 캐비닛 배송"
                        }
                        {
                          "C" === item.type && "F2" === item.free_yn && "월 기본수거"
                        }
                        {
                          "D" === item.type && "F2" === item.free_yn && "월 기본배송"
                        }
                        {
                          "F3" === item.free_yn && "쿠폰 사용"
                        }
                        {
                          "P1" === item.free_yn && "10,000원"
                        }
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div> */}
          <div className="paymentHistories">
            {
              historyList.length ?
              historyList.map(item => {

                return (
                  <div key={item.seq} className="paymentHistory">
                    <div className="row row1">
                      <div className="date">{item.regdate ? dateFormat2(item.regdate) : ""}</div>
                      <div className={"GOODS" === item.pay_type ? "mark mark1" : "COUPON" === item.pay_type ? "mark mark2" : "ADD" === item.pay_type ? "mark mark3" : "mark mark4"}>{"GOODS" === item.pay_type ? "월구독요금" : "COUPON" === item.pay_type ? "쿠폰" : "ADD" === item.pay_type ? "추가운송요금" : "결제취소"}</div>
                      {/* 서비스 원가를 표시해줘야함. 일단 현재는 api가 완전하지 않으므로 실제 카드가 결제한 값을 표시하게 했음. */}
                      <span className={item.point ? "price1 lineTr" : "price1"}>{PriceToString(item.amount + item.point)}원</span>
                      {/* 포인트 할인 있는지 없는지에 따라 추가 태그 */}
                      {/* <span className="price2">포인트 할인 <span className="t1">-10,000원</span></span> */}
                      {
                        item.point ? 
                        <span className="price2">포인트 할인 <span className="t1">-{PriceToString(item.point)}</span></span>
                        :
                        <></>
                      }
                    </div>

                    <div className="title">{item.goodsName}</div>
                    <div className="period">
                      {
                        !item.service_date_st || !item.service_date_ed ?
                        <></>
                        :
                        <>{dateFormat2(item.service_date_st)} ~ {dateFormat2(item.service_date_ed)}</>
                      }  
                    </div>
                    <div className="row payment">
                      <span className="t1">신용카드 {EncryptCardNum(item.card_num)}</span>
                      <span className="t2">총 <span className="bold">{PriceToString(item.amount)}</span>원</span>
                    </div>
                  </div>
                );
              })
              :
              <></>
            }
          </div>
        </div>
      </div>

      <MainMenu/>

      <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default HistoryComp;