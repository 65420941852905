import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getMemberPurchasedServiceListAPI } from "../../apis/MemberAPICalls";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";
import { TEST_IMAGE } from "../../port/Port";
import { getCabinetSizeListAPI } from "../../apis/ServiceAPICalls";
import ServicePolicyModal from "../modal/ServicePolicyModal";

function MainServiceDetail() {

  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    size: "",
    month: "12",
    isElevator: false,
    sng_policy1: "N",
    sng_policy2: "N",
    sng_policy3: "N",
  });
  const [list, setList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [sizeOpt, setSizeOpt] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [policyNum, setPolicyNum] = useState(0);
  const memberReducer = useSelector(state => state.memberReducer);

  const moveBack = () => {
    navigate("/");
  };

  const openModal = e => {
    setModalStatus(true);
    setPolicyNum(e.target.getAttribute("value"));
  };

  const onChangeHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onCheckHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.checked
    });
  };

  const onCheckPolicyHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.checked ? "Y" : "N"
    });
  };

  const checkAutoPayDate = val => {
    const now = new Date();
    const edDate = new Date(val);
    edDate.setDate(edDate.getDate() + 2);
    edDate.setHours(0);
    edDate.setMinutes(0);
    edDate.setSeconds(0);

    return now < edDate;
  };

  const agreeAll = () => {
    const p1 = document.getElementsByName("sng_policy1")[0];
    const p2 = document.getElementsByName("sng_policy2")[0];
    const p3 = document.getElementsByName("sng_policy3")[0];

    p1.checked = true;
    p2.checked = true;
    p3.checked = true;

    setData({
      ...data,
      sng_policy1: "Y",
      sng_policy2: "Y",
      sng_policy3: "Y",
    });
  };

  const purchaseService = () => {
    if("0" === decrypt(sessionStorage.getItem("mem_seq"))) {
      navigate("/login");
      return;
    }

    if(sizeOpt.length && !data.size) {
      Swal.fire({
        text: "캐비닛 사이즈를 선택해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
      });
      return;
    }

    if(!memberReducer.coll_addr_available || !memberReducer.del_addr_available) {
      Swal.fire({
        text: "수거지 정보 등록 후 서비스 신청이 가능합니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        if(res.isConfirmed) navigate("/mypage/addressList?type=C");
      });
      return;
    }

    if("Y" !== data.sng_policy1 || "Y" !== data.sng_policy2) {
      Swal.fire({
        text: "약관에 동의한 이후에 서비스 신청이 가능합니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if(list.length && checkAutoPayDate(list[0].service_date_ed)) {
      Swal.fire({
        text: "이미 사용중인 서비스가 있습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    } else navigate("/payment", {state: [state, data]});
  };

  useEffect(() => {
    ScrollToTop();

    if(!list.length) {
      (async () => {
        setList(await getMemberPurchasedServiceListAPI(decrypt(sessionStorage.getItem("mem_seq"))));
      })();
    }

    (async () => {
      setSizeList(await getCabinetSizeListAPI());
    })();

    if(!state.cabinet_size) {
      setData({
        ...data,
        size: "none"
      });
    }
  }, []);

  useEffect(() => {
    if(sizeList.length && !sizeOpt.length && state.cabinet_size) {
      const arr = state.cabinet_size.split(", ");
      const opt = [];

      sizeList.map(item => {
        arr.map(seq => {
          if(item.seq === parseInt(seq)) opt.push(item);
        });
      });

      setSizeOpt(opt);
    }
  }, [sizeList]);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>서비스 안내</h1>
          <div className="appicon"></div>
        </div>
      </header>

      <ServicePolicyModal status={modalStatus} setStatus={setModalStatus} type={policyNum} setType={setPolicyNum} data={data} setData={setData}/>

      <div className="contents">
        <div className="centerWrap">
          <img src={`${TEST_IMAGE}/goods/${state.filename_svr}`} className="serviceImg1" alt="" style={{ margin: "0 auto 20px" }}/>
          <div style={{ textAlign: "center", textDecoration: "underline", fontWeight: "600" }}>{state.title}</div><br/>
          <div className="serviceSubTitle" dangerouslySetInnerHTML={{__html: state.info}}></div>

          {
            sizeOpt.length ?
            <>
              <div className="dataLabel">
                <div className="label">캐비닛 사이즈</div>
              </div>
              <div className="dataWrap">
                <select className="selectNormal" title="사이즈" name="size" defaultValue="" onChange={onChangeHandler}>
                  {/* <option value="R1">SIZE : R1 (000 x 000)</option>
                  <option value="R2">SIZE : R2 (000 x 000)</option>
                  <option value="R3">SIZE : R3 (000 x 000)</option>
                  <option value="R4">SIZE : R4 (000 x 000)</option> */}
                  <option value="">----------------------사이즈 선택----------------------</option>
                  {
                    sizeOpt.map(item => {

                      return (
                        <option key={`size_${item.seq}`} value={item.seq}>{item.size}</option>
                      );
                    })
                  }
                </select>
              </div>
            </>
            :
            <></>
          }

          <div className="dataLabel">
            <div className="label">월 편도(수거/배송) 횟수</div>
          </div>
          <div className="priceWrap" style={{ display: "flex", alignItems: "center" }}>
            <div className="row">
              <div dangerouslySetInnerHTML={{__html: state.content}}></div>
            </div>
          </div>
          <div className="warning">
            {
              !state.times ?
              <div className="txt">편도 운송요금은 수거 및 배송에 각각 10,000원씩 부과됩니다.</div>
              :
              <div className="txt">초과 운송요금은 수거 및 배송에 각각 10,000원씩 부과됩니다.</div>
            }
          </div>

          <div className="dataLabel">
            <div className="label">월 구독기간 (4주)</div>
          </div>
          <div className="priceWrap">
            <div className="row">
              {0 !== state.discount_price && state.discount_price !== state.regular_price ? <div className="originalPrice">정상가격 : 월 {state.regular_price.toLocaleString()}원</div> : <></>}
              <div className="label">월 정액요금</div>
              <div className="price">월 <span className="t1">{state.regular_price !== state.discount_price ? Number(state.regular_price - state.discount_price).toLocaleString() : state.regular_price.toLocaleString()}</span> 원</div>
            </div>
          </div>

          {/* 할인 적용중인 서비스일때 사용 */}
          {/* <div className="priceWrap">
            <div className="row">
              <div className="label">선납하기</div>
              <div className="price original">월 <span className="t1">77,000</span> 원</div>
            </div>
            <div className="row">
              <div className="price">월 <span className="t1">66,000</span> 원</div>
            </div>
          </div>

          <div className="dataWrap">
            <select className="selectNormal" title="이용기간" name="month" onChange={onChangeHandler} defaultValue="12">
              <option value="12">12개월</option>
              <option value="24">24개월</option>
              <option value="36">36개월</option>
            </select>
          </div> */}

          <div className="dataWrap">
            <label className="brisakInput">
              <input type="checkbox" name="isElevator" onChange={onCheckHandler}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">수거/배송 지역에 엘리베이터가 있습니다.</div>
              </div>
            </label>
          </div>

          {
            data.isElevator ?
            <></>
            :
            <div className="warning">
              <div className="txt">엘리베이터가 없는 건물에는 서비스 제공이 어렵습니다.</div>
            </div>
          }

          <div className="priceWrap">
            <div className="row">
              {0 !== state.discount_deposit ? <div className="originalPrice">보증금 : {state.deposit.toLocaleString()}원</div> : <></>}
              <div className="label">캐비닛 보증금</div>
              <div className="price">할인보증금 <span className="t1">{Number(state.deposit - state.discount_deposit).toLocaleString()}</span> 원</div>
            </div>
          </div>
          <div className="warning">
            <div className="txt">캐비닛 보증금은 서비스 해지 및 캐비닛 반납 시, 전액 환불됩니다. </div>
          </div>

          <br/>
          <div className="dataWrap">
            <button tabIndex={0} className="buttonDone" title="모든 약관 동의" onClick={agreeAll}>모든 약관 동의</button>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="sng_policy1" onChange={onCheckPolicyHandler} checked={"Y" === data.sng_policy1 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(필수) 스토르앤고 이용약관</div>
              </div>
            </label>

            <span className="buttonShowPolicy" style={{ cursor: "pointer" }} value={1} onClick={openModal}></span>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="sng_policy2" onChange={onCheckPolicyHandler} checked={"Y" === data.sng_policy2 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(필수) 스토르앤고 개인정보처리방침</div>
              </div>
            </label>

            <span className="buttonShowPolicy" style={{ cursor: "pointer" }} value={2} onClick={openModal}></span>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="sng_policy3" onChange={onCheckPolicyHandler} checked={"Y" === data.sng_policy3 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(선택) 마케팅 정보 수신 동의</div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className="bottomButtons">
        <button tabIndex={0} className={data.isElevator ? "buttonDone" : "buttonDone" /*"buttonDone disabledBtn"*/} onClick={purchaseService} disabled={data.isElevator ? false : true}>서비스 신청하기</button>
      </div>
    </>
  );
}

export default MainServiceDetail;