import { createActions, handleActions } from "redux-actions";

const initialState = {
  service_seq: "",
  payment_goods_seq: "",
  memInfo: {},
  time: 0,
  freeUsedCnt: 0,
  cardInfo: {},
  vote: {
    vote1: 0,
    vote2: 0,
    vote3: 0,
  },
  cAddrCnt: 0,
  dAddrCnt: 0,
  coupon: 0,
  coll_addr_available: false,
  del_addr_available: false,
  isFirstPurchase: true,
}

export const GET_SERVICE_SEQ = "delivery/GET_SERVICE_SEQ";
export const GET_PAYMENT_GOODS_SEQ = "delivery/GET_PAYMENT_GOODS_SEQ";
export const GET_MEMBER_INFO = "member/GET_MEMBER_INFO";
export const POST_MEMBER_TIME_AND_FREE_CNT = "delivery/POST_MEMBER_TIME_AND_FREE_CNT";
export const POST_MEMBER_DEFAULT_PAYMENT_INFO = "mypage/POST_MEMBER_DEFAULT_PAYMENT_INFO";
export const POST_MEMBER_VOTE_INFO = "member/POST_MEMBER_VOTE_INFO";
export const POST_MEMBER_COLL_ADDR_CNT = "member/POST_MEMBER_COLL_ADDR_CNT";
export const POST_MEMBER_DEL_ADDR_CNT = "member/POST_MEMBER_DEL_ADDR_CNT";
export const POST_MEMBER_COUPON_CNT = "member/POST_MEMBER_COUPON_CNT";
export const POST_MEMBER_SERVICE_AVAILABLE = "member/POST_MEMBER_SERVICE_AVAILABLE";
export const POST_MEMBER_FIRST_PURCHASE = `member/POST_MEMBER_FIRST_PURCHASE`;
export const CLEAR_MEMBER_PERSIST = "member/CLEAR_MEMBER_PERSIST";

const actions = createActions({
  [GET_SERVICE_SEQ]: () => {},
  [GET_PAYMENT_GOODS_SEQ]: () => {},
  [GET_MEMBER_INFO]: () => {},
  [POST_MEMBER_TIME_AND_FREE_CNT]: () => {},
  [POST_MEMBER_DEFAULT_PAYMENT_INFO]: () => {},
  [POST_MEMBER_VOTE_INFO]: () => {},
  [POST_MEMBER_COLL_ADDR_CNT]: () => {},
  [POST_MEMBER_DEL_ADDR_CNT]: () => {},
  [POST_MEMBER_COUPON_CNT]: () => {},
  [POST_MEMBER_SERVICE_AVAILABLE]: () => {},
  [POST_MEMBER_FIRST_PURCHASE]: () => {},
  [CLEAR_MEMBER_PERSIST]: () => {},
});

const memberReducer = handleActions({
  [GET_SERVICE_SEQ]: (state, { payload }) => {
    state.service_seq = payload;

    let newState = {...state};

    return newState;
  },

  [GET_PAYMENT_GOODS_SEQ]: (state, { payload }) => {
    state.payment_goods_seq = payload;

    let newState = {...state};

    return newState;
  },

  [GET_MEMBER_INFO]: (state, { payload }) => {
    state.memInfo = {...payload};

    let newState = {...state};

    return newState;
  },

  [POST_MEMBER_TIME_AND_FREE_CNT]: (state, { payload }) => {
    state.time = payload[0];
    state.freeUsedCnt = payload[1];

    let newState = {...state};

    return newState;
  },

  [POST_MEMBER_DEFAULT_PAYMENT_INFO]: (state, { payload }) => {
    state.cardInfo = {...payload};

    let newState = {...state};

    return newState;
  },

  [POST_MEMBER_VOTE_INFO]: (state, { payload }) => {
    state.vote = {...payload};

    let newState = {...state};

    return newState;
  },

  [POST_MEMBER_COLL_ADDR_CNT]: (state, { payload }) => {
    state.cAddrCnt = payload;

    let newState = {...state};

    return newState;
  },

  [POST_MEMBER_DEL_ADDR_CNT]: (state, { payload }) => {
    state.dAddrCnt = payload;

    let newState = {...state};

    return newState;
  },

  [POST_MEMBER_COUPON_CNT]: (state, { payload }) => {
    state.coupon = payload;

    let newState = {...state};

    return newState;
  },

  [POST_MEMBER_SERVICE_AVAILABLE]: (state, { payload }) => {
    state.coll_addr_available = payload.coll;
    state.del_addr_available = payload.del;

    let newState = {...state};

    return newState;
  },

  [POST_MEMBER_FIRST_PURCHASE]: (state, { payload }) => {
    state.isFirstPurchase = payload;

    let newState = {...state};

    return newState;
  },

  [CLEAR_MEMBER_PERSIST]: () => {
    let newState = {
      service_seq: "",
      payment_goods_seq: "",
      memInfo: {},
      time: 0,
      freeUsedCnt: 0,
      cardInfo: {},
      vote: {
        vote1: 0,
        vote2: 0,
        vote3: 0,
      },
      cAddrCnt: 0,
      dAddrCnt: 0,
      coupon: 0,
      coll_addr_available: false,
      del_addr_available: false,
      isFirstPurchase: true,
    };

    return newState;
  },
}, initialState);

export default memberReducer;