import { useLocation, useNavigate } from "react-router-dom";
import PriceToString from "../../js/PriceToString";
import { useEffect, useState } from "react";
import { getServiceListAPI } from "../../apis/ServiceAPICalls";
import DiscountPercentage from "../../js/DiscountPercentage";
import CreateToken from "../../js/CreateToken";
import { getMemberLastPurchasedServiceSeq, getMemberPurchasedServiceListAPI, postChangeMemberPurchasedServiceAPI } from "../../apis/MemberAPICalls";
import Swal from "sweetalert2";
import ConfirmCancelServiceModal from "../../components/modal/ConfirmCancelServiceModal";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";

function ChangeService() {

  const navigate = useNavigate();
  const { state } = useLocation();
  const serviceInfo = state[0];
  const purchasedInfo = state[1];
  const recentApplyInfo = state[2];
  const [serviceList, setServiceList] = useState([]);
  const [csModalStatus, setCsModalStatus] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);

  const calcNewDate = (str, num) => {
    const date = new Date(str);

    if(1 === num) {
      date.setDate(date.getDate() + 1);

      const y = date.getFullYear();
      const m = String(date.getMonth() + 1).padStart(2, "0");
      const d = String(date.getDate()).padStart(2, "0");

      return `${y}-${m}-${d}`;
    } else if(2 === num) {
      date.setDate(date.getDate() + 28);

      const y = date.getFullYear();
      const m = String(date.getMonth() + 1).padStart(2, "0");
      const d = String(date.getDate()).padStart(2, "0");

      return `${y}-${m}-${d}`;
    }
  };

  const [form, setForm] = useState({
    mem_seq: decrypt(sessionStorage.getItem("mem_seq")),
    token: "",
    payment_goods_seq: 0,  //변경 상품 번호
    cabinet_seq: purchasedInfo.cabinet_seq, // 현재 서비스의 캐비닛 번호
    service_date_st: calcNewDate(purchasedInfo.service_date_ed, 1),  // 현재 서비스의 종료일자 +1일
    service_date_ed: calcNewDate(purchasedInfo.service_date_ed, 2),  // 현재 서비스의 종료일자 +28일
  });

  const moveBack = () => {
    navigate("/history");
  };

  const dateFormat = date => {
    let formated = new Date(date);
    formated.setDate(formated.getDate() + 1);

    const edDate = formated.toLocaleString();
    const year = edDate.substring(0, 4);
    const month = (edDate.substring(6, 8).replace(".", "")).padStart(2, "0");
    const day = (edDate.substring(9, 11).replace(".", "")).padStart(2, "0");

    return `${year}년 ${month}월 ${day}일`;
  };

  const onChangeHandler = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const openCsModal = () => {
    setCsModalStatus(true);
  };

  const submitChangeService = () => {
    if(!form.payment_goods_seq) {
      Swal.fire({
        text: "변경할 서비스를 선택해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if(!changeStatus) {
      Swal.fire({
        text: "이미 변경을 신청한 서비스가 존재합니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    Swal.fire({
      html: "변경한 서비스는 다음달부터 적용됩니다. 서비스 변경 후, 두 달 동안 재변경이 불가합니다. 서비스 변경을 신청하겠습니까?",
      confirmButtonText: "변경하기",
      showCancelButton: true,
      cancelButtonText: "취소",
      cancelButtonColor: "black",
      position: "center",
      confirmButtonColor: "#0064ff",
    })
    .then(async (res) => {
      if(res.isConfirmed) {
        const resCode = await postChangeMemberPurchasedServiceAPI(form, CreateToken());

        if(1 === resCode) {
          // alert("서비스 변경 성공. 현재 서비스가 종료된 후 변경된 서비스가 적용됩니다.");
          // navigate("/");
          Swal.fire({
            
            html: "서비스 변경 성공.<br/>현재 서비스가 종료된 후 변경된 서비스가 적용됩니다.",
            confirmButtonText: "확인",
            position: "center",
            confirmButtonColor: "#0064ff",
          })
          .then(res => navigate("/"));
          return;
        } else {
          // alert("서비스 변경중 오류가 발생했습니다. 다시 한번 시도해주세요.");
          Swal.fire({
            
            html: "서비스 변경중 오류가 발생했습니다.<br/>다시 한번 시도해주세요.",
            confirmButtonText: "확인",
            position: "center",
            confirmButtonColor: "#0064ff",
          })
          .then();
          return;
        }
      }
    });
  };

  useEffect(() => {
    ScrollToTop();

    if(!serviceList.length) {
      (async () => {
        const list = await getServiceListAPI();

        setServiceList(list.filter(item => serviceInfo.seq !== item.seq));
      })();
    }

    (async () => {
      const promise = await getMemberPurchasedServiceListAPI(decrypt(sessionStorage.getItem("mem_seq")));
      const lastSeq = await getMemberLastPurchasedServiceSeq(decrypt(sessionStorage.getItem("mem_seq")));

      if(promise[0].seq === lastSeq) setChangeStatus(true);
      else setChangeStatus(false);
    })();
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>서비스 변경</h1>
        </div>
      </header>

      <ConfirmCancelServiceModal status={csModalStatus} setStatus={setCsModalStatus} purchasedInfo={purchasedInfo} type={recentApplyInfo && recentApplyInfo.apply_date ? true : false}/>

      <div className="contents deliveryContents type2">
        <div className="centerWrap">
          <div className="titleWrap2">
            <div className="title">현재 이용중인 서비스</div>
          </div>
          <div className="box1">
            <div className="service">
              <div className="row">
                <div className="status silver">SILVER</div>
              </div>

              <div className="serviceNow">
                <div className="row">
                  <div className="serviceNowTitle type2">{serviceInfo.title}</div>
                </div>
                <div className="row row4">
                  <div className="servicePrice">{`월 ${PriceToString(serviceInfo.regular_price - serviceInfo.discount_price)}원`}</div>
                  <div className="payDate">{`다음 자동 결제일 : ${dateFormat(purchasedInfo.service_date_ed)}`}</div>
                </div>
              </div>

              <div className="warning">
                <div className="txt">편도 수거/배송은 이월된 쿠폰 차감 > 당월 편도 횟수 차감 > 초과 운송요금 결제 순으로 적용됩니다.</div>
              </div>
              <div className="warning">
                <div className="txt">서비스는 2개월마다 1회 변경 가능합니다.</div>
              </div>
            </div>
          </div>

          <div className="titleWrap2">
            <div className="title">변경 가능한 서비스</div>
          </div>
          <div className="otherServices">
            {
              serviceList.length ?
              serviceList.map(item => {

                return (
                  <label key={item.seq} className="otherService">
                    <input type="radio" name="payment_goods_seq" onChange={onChangeHandler} value={item.seq}/>
                    <div className="icon"></div>
                    <div className="title">{item.title}</div>
                    <div className="content">{item.content}</div>
                    {/* <div className="servicePrice"><span className="bold">월(4주)구독요금</span> {PriceToString(item.regular_price - item.discount_price)}원</div> */}
                    <div className="priceRow">
                      {
                        item.discount_price ?
                        <>
                          <span className="period">월</span>
                          <span className="originalPrice">{PriceToString(item.regular_price)}</span>
                          <div className="t1">
                            <span className="arrow"></span>
                            <span className="discount">{DiscountPercentage(item.regular_price, item.discount_price)}%</span>
                            <span className="price"><span className="bold">{PriceToString(item.regular_price - item.discount_price)}</span>원</span>
                          </div>
                        </>
                        :
                        <span className="period">월 {PriceToString(item.regular_price)}원</span>
                      }
                    </div>
                  </label>
                );
              })
              :
              <></>
            }
            {
              serviceInfo && Object.keys(serviceInfo).length ?
              "N" === purchasedInfo.refund_yn && recentApplyInfo && "D" === recentApplyInfo.type && ("C2" === recentApplyInfo.status || "E1" === recentApplyInfo.status) ?
              <label className="otherService" onClick={openCsModal}>서비스 해지 신청하기</label>
              :
              "N" === purchasedInfo.refund_yn && recentApplyInfo && "C" === recentApplyInfo.type && "D1" === recentApplyInfo.status ?
              <label className="otherService" onClick={openCsModal}>서비스 해지 신청하기</label>
              :
              <></>
              :
              <></>
            }
          </div>

          <div className="row">
            {
              serviceInfo && Object.keys(serviceInfo).length ?
              <>
                {
                  "N" === purchasedInfo.refund_yn && !recentApplyInfo ?
                  <button className="buttonCancelPayment" onClick={openCsModal}>결제 취소하기</button>
                  :
                  <></>
                }
              </>
              :
              <></>
            }
          </div>
        </div>
      </div>

      <div className="bottomButtons">
        {/* 
          상품이 후불 상품이면 서비스 갱신시점 때 결제가 진행(갱신 시점때는 변경한 서비스가 표시되야 함),
          상품이 선불 상품이면 바로 PG사 결제 페이지로 이동

          [serviceInfo.pay_gubun]
          "POST" : 후불
          "PRE" : 선불
        */}
        <button tabIndex={0} className="buttonDone" onClick={submitChangeService}>선택하기</button>
      </div>
    </>
  );
}

export default ChangeService;