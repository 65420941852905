import { useEffect, useState } from "react";
import { getMemberPaymentListAPI, paymentDuplicateCheckAPI, postMemberPurchasedServiceInfoAPI, postMemberPurchasedServiceInfoAndCardInfoAPI } from "../../apis/MemberAPICalls";
import { useLocation, useNavigate } from "react-router-dom";
import CreateToken from "../../js/CreateToken";
import PaymentPolicyModal from "../modal/PaymentPolicyModal";
import Swal from "sweetalert2";
import ScrollToTop from "../../js/ScrollToTop";
import { decrypt } from "../../js/encrypt";
import { useSelector } from "react-redux";

function PaymentComp() {

  const memberReducer = useSelector(state => state.memberReducer);
  const [paymentInfo, setPaymentInfo] = useState({});
  const mem_seq = sessionStorage.getItem("mem_seq") ? decrypt(sessionStorage.getItem("mem_seq")) : "";
  const [data, setData] = useState({
    payment_seq: "",
    cardNum1: "",
    cardNum2: "",
    cardNum3: "",
    cardNum4: "",
    expireM: "",
    expireY: "",
    name : "",
    installmentM: 0,
    card_pw: '',
    policy1: "N",
    policy2: "N",
    policy3: "N",
    ssn1: "",
    ssn2: "",
    isFirstPurchase: memberReducer.isFirstPurchase ? memberReducer.isFirstPurchase : false,
    eventCode: memberReducer.memInfo.event_code ? memberReducer.memInfo.event_code : "",
  });
  const [modalStatus, setModalStatus] = useState(false);
  const [policyNum, setPolicyNum] = useState(0);
  const [isAvailable1, setIsAvailable1] = useState(false);
  const [isAvailable2, setIsAvailable2] = useState(false);
  const { state } = useLocation();
  const serviceInfo = state[0];
  const addInfo = state[1];
  const [purchaseData, setPurchaseData] = useState({
    mem_seq: mem_seq,
    payment_goods_seq: serviceInfo.seq,
    service_date_ed: serviceInfo.date_ed,
    service_date_st: serviceInfo.date_st,
    service_month: 1,
    m_amt: serviceInfo.regular_price - serviceInfo.discount_price,
	  d_amt: serviceInfo.deposit - serviceInfo.discount_deposit,
    token: CreateToken(),
  });
  const navigate = useNavigate();

  const moveBack = () => {
    window.history.back();
  };

  const openModal = e => {
    setModalStatus(true);
    setPolicyNum(e.target.getAttribute("value"));
  };

  const onChangeHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const onCheckHandler = e => {
    setData({
      ...data,
      [e.target.name]: e.target.checked ? "Y" : "N"
    });
  };

  const onlyNums = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  };

  const moveNextCardNumInput = e => {
    const targetEl = document.getElementsByName(e.target.name)[0];
    const nextTarget = document.getElementsByName("expireM")[0];
    targetEl.value = e.target.value;

    // next input
    if((48 <= e.keyCode && e.keyCode <= 57) || (96 <= e.keyCode && e.keyCode <= 105)) {
      if("cardNum4" !== e.target.name && e.target.value.length === e.target.maxLength && e.target.nextElementSibling.nextElementSibling) {
        e.target.nextElementSibling.nextElementSibling.focus();
      } else if(e.target.value.length === e.target.maxLength && !e.target.nextElementSibling) {
        nextTarget.focus();
      }
    }

    // backspace
    if(8 === e.keyCode) {
      if(!e.target.value.length && e.target.previousElementSibling && e.target.previousElementSibling.previousElementSibling) {
        e.target.previousElementSibling.previousElementSibling.focus();
      }
    }
  };

  const moveNextCardExpireInput = e => {
    const targetEl = document.getElementsByName(e.target.name)[0];
    const nextTarget = document.getElementsByName("card_pw")[0];
    targetEl.value = e.target.value;

    // next input
    if((48 <= e.keyCode && e.keyCode <= 57) || (96 <= e.keyCode && e.keyCode <= 105)) {
      if(e.target.value.length === e.target.maxLength && e.target.nextElementSibling && e.target.nextElementSibling.nextElementSibling) {
        e.target.nextElementSibling.nextElementSibling.focus();
      } else if(e.target.value.length === e.target.maxLength && !e.target.nextElementSibling) {
        nextTarget.focus();
      }
    }

    // backspace
    if(8 === e.keyCode) {
      if(!e.target.value.length && e.target.previousElementSibling && e.target.previousElementSibling.previousElementSibling) {
        e.target.previousElementSibling.previousElementSibling.focus();
      }
    }
  };

  const agreeAll = () => {
    const p1 = document.getElementsByName("policy1")[0];
    const p2 = document.getElementsByName("policy2")[0];
    const p3 = document.getElementsByName("policy3")[0];

    p1.checked = true;
    p2.checked = true;
    p3.checked = true;

    setData({
      ...data,
      policy1: "Y",
      policy2: "Y",
      policy3: "Y",
    });
  };

  const calcCardExp = () => {
    const comparison = new Date();
    comparison.setMonth(comparison.getMonth() + 3);

    const cardExp = `20${data.expireY}-${data.expireM}`;
    const expDate = new Date(cardExp);
    expDate.setDate(expDate.getDate() + 1);

    return comparison < expDate;
  };
  
  const confirmPurchase = async () => {
    if(!data.cardNum1 || !data.cardNum2 || !data.cardNum3 || !data.cardNum4 || !data.card_pw || !data.expireM || !data.expireY || !data.name || !data.ssn1 || !data.ssn2) {
      Swal.fire({
        text: "필수 정보들을 입력하세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if("N" === data.policy1 || "N" === data.policy2 || "N" === data.policy3) {
      Swal.fire({
        text: "필수 약관에 동의해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    const dupCode = await paymentDuplicateCheckAPI(data.cardNum1 + data.cardNum2 + data.cardNum3 + data.cardNum4, mem_seq, CreateToken());
    let resCode;

    if(paymentInfo && Object.keys(paymentInfo).length) {
      if(-1 === dupCode && paymentInfo.card_num === data.cardNum1 + data.cardNum2 + data.cardNum3 + data.cardNum4 && paymentInfo.card_expiration_mm === data.expireM && paymentInfo.card_expiration_yy === data.expireY) resCode = await postMemberPurchasedServiceInfoAPI(data, purchaseData, addInfo, CreateToken());
      else resCode = await postMemberPurchasedServiceInfoAndCardInfoAPI(data, purchaseData, addInfo, CreateToken());
    } else resCode = await postMemberPurchasedServiceInfoAndCardInfoAPI(data, purchaseData, addInfo, CreateToken());

    if(1 !== resCode) {
      Swal.fire({
        text: "결제 실패",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    } else {
      if(memberReducer.isFirstPurchase && "HOMESXSTORR" === data.eventCode && 1 === serviceInfo.seq) {
        Swal.fire({
          text: "첫 결제 이벤트로 상품이 무료로 결제됐습니다.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then(res => {
          if(memberReducer.isFirstPurchase) {
            Swal.fire({
              text: "캐비닛을 배송받을 주소를 설정하셔야합니다. 페이지로 이동합니다.",
              confirmButtonText: "확인",
              position: "center",
              confirmButtonColor: "#0064ff",
            })
            .then(result => navigate("/mypage/addressList?type=D"), {replace: true});
          } else navigate("/delivery");
        });
        return;
      } else {
        Swal.fire({
          text: "성공적으로 결제했습니다.",
          confirmButtonText: "확인",
          position: "center",
          confirmButtonColor: "#0064ff",
        })
        .then(res => {
          if(memberReducer.isFirstPurchase) {
            Swal.fire({
              text: "캐비닛을 배송받을 주소를 설정하셔야합니다. 페이지로 이동합니다.",
              confirmButtonText: "확인",
              position: "center",
              confirmButtonColor: "#0064ff",
            })
            .then(result => navigate("/mypage/addressList?type=D"), {replace: true});
          } else navigate("/delivery");
        });
        return;
      }
    }
  };

  useEffect(() => {
    ScrollToTop();

    if(paymentInfo && !Object.keys(paymentInfo).length && mem_seq) {
      (async () => {
        const list = await getMemberPaymentListAPI(mem_seq);
        const info = list.filter(item => "Y" === item.default_yn);

        setPaymentInfo(info[0]);
      })();
    }
  }, []);

  useEffect(() => {
    if(paymentInfo && Object.keys(paymentInfo).length && mem_seq) {
      setData({
        ...data,
        payment_seq: paymentInfo.seq,
        cardNum1: paymentInfo.card_num.substring(0, 4),
        cardNum2: paymentInfo.card_num.substring(4, 8),
        cardNum3: paymentInfo.card_num.substring(8, 12),
        cardNum4: paymentInfo.card_num.substring(12, 16),
        expireM: paymentInfo.card_expiration_mm,
        expireY: paymentInfo.card_expiration_yy,
        name: paymentInfo.card_entry_name
      });
    }
  }, [paymentInfo]);

  useEffect(() => {
    if(4 === data.cardNum1.length && 4 === data.cardNum2.length && 4 === data.cardNum3.length && (4 === data.cardNum4.length || 3 === data.cardNum4.length) && 2 === data.expireM.length && 2 === data.expireY.length && 2 === data.card_pw.length && data.name && 6 === data.ssn1.length && 1 === data.ssn2.length && "Y" === data.policy1 && "Y" === data.policy2 && "Y" === data.policy3) setIsAvailable1(true);
    else setIsAvailable1(false);

    if(calcCardExp()) setIsAvailable2(true);
    else setIsAvailable2(false);

    if("00" === data.expireM) {
      setData({
        ...data,
        expireM: ""
      });
    } else {
      if(Number(data.expireM) > 12) {
        setData({
          ...data,
          expireM: ""
        });
      }
    }
  }, [data]);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>간편 결제</h1>
        </div>
      </header>

      <PaymentPolicyModal status={modalStatus} setStatus={setModalStatus} type={policyNum} setType={setPolicyNum} data={data} setData={setData}/>

      <div className="contents">
        <div className="centerWrap">
          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">카드번호</div>
          </div>
          <div className="dataWrap">
            <input name="cardNum1" type="text" className="inputNormal inputNormal1in4 inputUnderline" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardNumInput} value={data.cardNum1} maxLength={4}/>
            <div className="bw">-</div>
            <input name="cardNum2" type="text" className="inputNormal inputNormal1in4 inputUnderline" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardNumInput} value={data.cardNum2} maxLength={4}/>
            <div className="bw">-</div>
            <input name="cardNum3" type="text" className="inputNormal inputNormal1in4 inputUnderline" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardNumInput} value={data.cardNum3} maxLength={4}/>
            <div className="bw">-</div>
            <input name="cardNum4" type="text" className="inputNormal inputNormal1in4 inputUnderline" title="카드번호" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardNumInput} value={data.cardNum4} maxLength={4}/>
          </div>
          {
            4 > data.cardNum1.length || 4 > data.cardNum2.length || 4 > data.cardNum3.length || 3 > data.cardNum4.length ?
            <div className="warning">
              <div className="txt">신용카드 번호를 정확히 입력하세요.</div>
            </div>
            :
            <></>
          }

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">유효기간</div>
          </div>
          <div className="dataWrap">
            <input name="expireM" type="text" className="inputNormal inputNormal1in2" title="유효기간 월" placeholder="MM" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardExpireInput} value={data.expireM} maxLength={2}/>
              <div className="bw">-</div>
            <input name="expireY" type="password" className="inputNormal inputNormal1in2" title="유효기간 년도" placeholder="YY" onInput={onlyNums} onChange={onChangeHandler} onKeyUp={moveNextCardExpireInput} value={data.expireY} maxLength={2}/>
          </div>
          {
            !isAvailable2 ?
            <div className="warning">
              <div className="txt">유효기간이 3개월 이상 남은 카드를 등록하셔야합니다.</div>
            </div>
            :
            <></>
          }

          {
            !isAvailable2 ?
            <div className="warning">
              <div className="txt">결제를 진행하기 위해선 유효기간이 최소 3개월 남은 카드여야합니다.</div>
            </div>
            :
            <></>
          }

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">카드 비밀번호(앞 2자리)</div>
          </div>
          <div className="dataWrap">
          <div className="inputStyleWrap" style={{ maxWidth: "200px"}}>
              <input name="card_pw" type="password" className="cardUnderline" title="카드 비밀번호" onInput={onlyNums} onChange={onChangeHandler} value={data.card_pw} maxLength={2}/>
              <div className="inputCenterTxt"></div>
              <input type="password" className="inputTrans"value="**" readOnly/>
            </div>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">이름</div>
          </div>
          <div className="dataWrap">
            <input name="name" type="text" className="inputNormal" placeholder="카드내 기재된 이름" title="이름" onChange={onChangeHandler} value={data.name}/>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">생년월일</div>
          </div>
          <div className="dataWrap">
            <div className="inputStyleWrap">
              <input type="text" className="birthUnderline" title="생년월일" name="ssn1" onInput={onlyNums} onChange={onChangeHandler} value={data.ssn1} maxLength={6}/>
              <div className="inputCenterTxt">-</div>
              <input type="password" className="birthUnderline2" title="성별" name="ssn2" onInput={onlyNums} onChange={onChangeHandler} value={data.ssn2} maxLength={1}/>
              <div className="etc">******</div>
            </div>
          </div>

          <div className="dataLabel">
            <div className="sp" title="필수"></div>
            <div className="label">할부개월</div>
          </div>
          <div className="dataWrap">
            <select defaultValue={0} name="installmentM" className="selectNormal" title="할부개월" onChange={onChangeHandler}>
              <option value={0}>일시불</option>
              <option value={2}>2개월</option>
              <option value={3}>3개월</option>
              <option value={4}>4개월</option>
              <option value={5}>5개월</option>
              <option value={6}>6개월</option>
              <option value={7}>7개월</option>
              <option value={8}>8개월</option>
              <option value={9}>9개월</option>
              <option value={10}>10개월</option>
              <option value={11}>11개월</option>
              <option value={12}>12개월</option>
            </select>
          </div>

          <br/>
          <div className="dataWrap">
            <button tabIndex={0} className="buttonDone" title="모든 약관 동의" onClick={agreeAll}>모든 약관 동의</button>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="policy1" onChange={onCheckHandler} checked={"Y" === data.policy1 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(나이스페이)전자금융거래 이용약관</div>
              </div>
            </label>

            <span className="buttonShowPolicy" style={{ cursor: "pointer" }} value={1} onClick={openModal}></span>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="policy2" onChange={onCheckHandler} checked={"Y" === data.policy2 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(나이스페이)개인정보 수집 및 이용 안내</div>
              </div>
            </label>

            <span className="buttonShowPolicy" style={{ cursor: "pointer" }} value={2} onClick={openModal}></span>
          </div>

          <div className="dataWrap dataWrapPolicy">
            <label className="brisakInput">
              <input type="checkbox" name="policy3" onChange={onCheckHandler} checked={"Y" === data.policy3 ? true : false}/>
              <div className="wrap">
                <div className="icon"></div>
                <div className="txt">(나이스페이)개인정보 제공 및 위탁 안내</div>
              </div>
            </label>

            <span className="buttonShowPolicy" style={{ cursor: "pointer" }} value={3} onClick={openModal}></span>
          </div>
        </div>
      </div>



      <div className="bottomButtons">
        <button tabIndex={0} className={isAvailable1 && isAvailable2 ? "buttonDone" : "buttonDone disabledBtn"} onClick={confirmPurchase} disabled={isAvailable1 && isAvailable2 ? false : true}>결제하기</button>
      </div>
    </>
  );
}

export default PaymentComp;