import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../js/ScrollToTop";
import { TEST_IMAGE } from "../../port/Port";
import { API_UPLOAD_FILE_DOWNLOAD, getNoticeDetailInfoAPI } from "../../apis/NoticeAPICalls";

function NoticeDetailComp() {

  const { state } = useLocation();
  const navigate = useNavigate();
  const [info, setInfo] = useState({});

  const moveBack = () => {
    navigate("/notice", {replace: true});
  };

  const dateFormat = date => {
    const year = date.substr(0, 4);
    const month = date.substr(5, 2);
    const day = date.substr(8, 2);
    
    return `${year}년 ${month}월 ${day}일`;
  };

  const downloadUploadedFile = () => {
    window.location.href = API_UPLOAD_FILE_DOWNLOAD + `gubun=NOTICE&seq=${info.result_file[0].seq}&seq2=${state}`;
  };

  useEffect(() => {
    ScrollToTop();

    if(!Object.keys(info).length) {
      (async () => {
        setInfo(await getNoticeDetailInfoAPI(state));
      })();
    }
  }, []);

  return (
    <>
      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveBack}></button>
          <h1>공지사항</h1>
        </div>
      </header>

      <div className="contents contents2">
        <div className="centerWrap">
          <div className="noticeWrap">
            <div className="noticeTitle">{Object.keys(info).length ? info.result.title : ""}</div>
            <div className="noticeDate">{Object.keys(info).length ? dateFormat(info.result.regdate) : ""}</div>
          </div>

          {
            Object.keys(info).length && "pdf" === info.result_file[0].filename_svr.slice(-3) ?
            <>
              <div className="noticeDetailContent" dangerouslySetInnerHTML={{__html: info.result.content}}></div>
              <div style={{paddingTop:"15px"}}>
                <div>${info.result_file[0].filename_org}</div>
                <button onClick={downloadUploadedFile}>[다운로드]</button>
              </div>
            </>
            :
            <>
              <img src={Object.keys(info).length ? `${TEST_IMAGE}/notice/` + info.result_file[0].filename_svr : ""} alt=""/>
              <div className="noticeDetailContent" dangerouslySetInnerHTML={{__html: Object.keys(info).length ? info.result.content : ""}}></div>
            </>
          }
        </div>
      </div>
    </>
  );
}

export default NoticeDetailComp;