import { useEffect, useRef, useState } from "react";
import SendVerifyCodeInterval from "../../js/SendVerifyCodeInterval";
import { checkMemberDuplicatePhoneAPI, checkMemberVerifyCodeAPI, loginAPI, postMemberVerifyCodeAPI } from "../../apis/MemberAPICalls";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ScrollToTop from "../../js/ScrollToTop";
import { PURGE } from "redux-persist";

function LoginStep2() {

  const navigate = useNavigate();
  const {state} = useLocation();
  const [form, setForm] = useState({
    phone: state ? state : "",
    verifyCode: "",
  });
  
  const [min, setMin] = useState("05");
  const [sec, setSec] = useState("00");
  min.padStart(2, "0");
  sec.padStart(2, "0");
  const time = useRef(299);
  const timerId = useRef(null);

  const onChangeHandler = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const onlyNums = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  };

  const moveToLogin = async () => {
    if("01012345678" === form.phone) {
      const res = await loginAPI(form.phone);
      if(1 === res) navigate("/", {replace: true});
      return;
    } else if(!form.phone || (11 !== form.phone.length && 10 !== form.phone.length)) {
      Swal.fire({
        text: "올바른 휴대폰 번호를 입력해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    if(0 >= time.current) {
      Swal.fire({
        
        html: "인증 제한시간이 초과됐습니다.<br/>인증번호를 다시 요청해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    const resCode = await checkMemberVerifyCodeAPI(form.phone, form.verifyCode);
    const vw = document.getElementsByClassName("verifyWarning")[0];
    
    if(1 === resCode) {
      vw.style.display = "none";
      const res = await loginAPI(form.phone);
      if(1 === res) navigate("/", {replace: true});
    } else {
      // alert("인증번호가 일치하지 않습니다.");
      vw.style.display = "block";
      Swal.fire({
        text: "인증번호가 일치하지 않습니다.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const moveToMainLogin = () => {
    navigate("/login");
  };

  const sendVerifyCode = async () => {
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

    if(!regPhone.test(form.phone)) {
      // alert("휴대폰번호를 입력해주세요.");
      Swal.fire({
        
        text: "휴대폰번호를 입력해주세요.",
        confirmButtonText: "확인",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then();
      return;
    }

    const duplicateCheck = await checkMemberDuplicatePhoneAPI(form.phone);

    if(1 === duplicateCheck) {
      Swal.fire({
        text: "회원으로 등록된 핸드폰 번호가 아닙니다.",
        confirmButtonText: "가입 페이지로 이동",
        showCancelButton: true,
        cancelButtonText: "취소",
        cancelButtonColor: "black",
        position: "center",
        confirmButtonColor: "#0064ff",
      })
      .then(res => {
        if(res.isConfirmed) navigate("/join");
        else return;
      });
      return;
    }

    const send = setInterval(() => {
      SendVerifyCodeInterval(timerId, time, setMin, setSec);
      clearInterval(send);
    }, 1000);

    const btn = document.getElementById("sendVerifyCodeBtn");
    const phone = document.getElementById("phoneInput");

    btn.disabled = true;
    phone.readOnly = true;

    (async () => {
      const code = await postMemberVerifyCodeAPI(form.phone);
            
      setTimeout(() => {
        setForm({
          ...form,
          verifyCode: code
        });
      }, 2000);
    })();
  };

  useEffect(() => {
    ScrollToTop();
  }, []);

  useEffect(() => {
    if(time.current < 0) {
      clearInterval(timerId.current);
      time.current = 299;
      setMin("05");
      setSec("00");
      document.getElementById("sendVerifyCodeBtn").disabled = false;
      document.getElementById("phoneInput").readOnly = false;
    }
  }, [sec]);

  return (
    <>
      <div className="topBigLogo">
        <div className="centerWrap">
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} className="joinLogo" alt="STORR"/>
        </div>
      </div>

      <header>
        <div className="centerWrap">
          <button tabIndex={0} className="back" title="뒤로" onClick={moveToMainLogin}></button>
          <h1>스토르앤고 로그인</h1>
        </div>
      </header>

      <div className="contents">
        <div className="centerWrap">
          {/* <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} className="joinLogo" alt="STORR" style={{ cursor: "pointer" }} onClick={moveToMain}/> */}

          {/* <div className="titleBar"></div> */}

              <div className="dataLabel">
                <div className="sp" title="필수"></div>
                <div className="label">휴대폰 번호</div>
              </div>
              <div className="dataWrap">
                <input id="phoneInput" type="text" className="inputNormal inputWithButton" title="휴대폰 번호" placeholder="숫자만 입력" name="phone" onInput={onlyNums} onChange={onChangeHandler} value={form.phone} maxLength={11}/>
                <button id="sendVerifyCodeBtn" tabIndex={0} className="buttonDark buttonWithInput" onClick={sendVerifyCode}><span>인증번호 요청</span></button>
              </div>
              <div className="warning"><div className="txt">입력하신 휴대폰 번호는 로그인 ID 및 긴급 연락처로 등록됩니다. </div></div>

              <div className="dataWrap">
                <input type="text" className="inputNormal" title="인증번호" placeholder="인증번호 숫자 6자리" name="verifyCode" onInput={onlyNums} onChange={onChangeHandler} value={form.verifyCode} maxLength={6}/>
                <div className="timeLimit" style={{ right: "25px" }}>{min}:{sec}</div>
              </div>
              <div className="warning verifyWarning"><div className="txt">정확한 인증번호를 다시 입력해 주세요.</div></div>
        </div>
      </div>

      <div className="bottomButtons">
            <button tabIndex={0} className="buttonDone" onClick={moveToLogin}><span>로그인</span></button>
          </div>

      <button tabIndex={0} className="gotoTop" id="gotoTop" title="화면 위로" onClick={moveToTop}></button>
    </>
  );
}

export default LoginStep2;